import vm from "@/main.js";

const toggleIntercomAlignment = ({ open }, vm) =>
  vm.$intercom?.update({ alignment: open ? "left" : "right" });

export default {
  namespaced: true,
  state: { open: false, mode: "list", createCombined: false },
  mutations: {
    toggleOpen(state, mode = "list") {
      mode === "create" && vm.$mixpanel.track("Start bookmark");
      Object.assign(state, {
        open: !state.open,
        mode,
        ...(state.open && { createCombined: false })
      });
      toggleIntercomAlignment(state, this._vm);
    },
    setMode: (state, mode) => {
      state.mode = mode;
      mode === "create" && vm.$mixpanel.track("Start bookmark");
    },
    enableCreateCombined: state => (state.createCombined = true)
  },
  actions: {
    createCombined: ({ commit }) => {
      commit("enableCreateCombined");
      commit("toggleOpen", "create");
    }
  }
};
