import Vue from "vue";
import VueGtag, { bootstrap } from "vue-gtag";
import router from "@/router.js";
import vm from "@/main.js";

const { VUE_APP_GOOGLE_ANALYTICS_ID, NODE_ENV } = process.env;

export const setGTagUser = ({ id, email, tenantName }) =>
  /@delight-data\.com$/.test(email) ||
  bootstrap().then(() => vm.$gtag.config({ user_id: `${tenantName}/${id}` }));

Vue.use(
  VueGtag,
  {
    config: { id: VUE_APP_GOOGLE_ANALYTICS_ID },
    appName: "Ignition",
    enabled: NODE_ENV === "production",
    bootstrap: false,
    pageTrackerScreenviewEnabled: true
  },
  router
);
