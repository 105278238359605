const AUTH0_CONFIG = {
  domain: "delight-data.eu.auth0.com",
  redirectUri: window.location.origin,
  // we will use the api/v2/ to access the user information as payload
  audience: "https://ignition.delight-data.com",
  responseType: "token id_token",
  scope: "openid profile"
};

export default AUTH0_CONFIG;
