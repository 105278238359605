<template>
  <div class="flex flex-col">
    <div class="flex">
      <el-input
        placeholder="Rechercher par nom, auteur ou tags"
        v-model="search"
        class="flex-grow"
        prefix-icon="el-icon-search"
        clearable
        autofocus
      ></el-input>

      <el-badge is-dot :hidden="!isSorted" class="mx-2">
        <el-button
          @click="() => toggleVisibleSettingDrawer('sort')"
          :type="visibleSettingDrawer === 'sort' ? 'primary' : ''"
          style="padding: 12px"
        >
          <fa :icon="['fal', 'sort-alt']" fixed-width></fa>
        </el-button>
      </el-badge>

      <el-badge is-dot :hidden="!isFiltered">
        <el-button
          @click="() => toggleVisibleSettingDrawer('filters')"
          :type="visibleSettingDrawer === 'filters' ? 'primary' : ''"
          style="padding: 12px"
        >
          <fa :icon="['fal', 'filter']" fixed-width></fa>
        </el-button>
      </el-badge>
    </div>

    <el-collapse
      v-model="visibleSettingDrawer"
      accordion
      class="bookmarks-collapse"
    >
      <el-collapse-item name="sort">
        <BookmarksSort v-model="sort" />
      </el-collapse-item>

      <el-collapse-item name="filters">
        <BookmarksFilters v-model="filters" />
      </el-collapse-item>
    </el-collapse>

    <div class="flex-grow mt-4 relative">
      <div class="absolute h-full w-full overflow-auto">
        <BookmarkItem
          v-for="{ qInfo: { qId: id }, qMeta } in computedBookmarks"
          :key="id"
          v-bind="{ id, ...qMeta }"
          @tagClick="tagClick"
        />

        <div class="pt-8" v-show="!bookmarksContext.bookmarks.length">
          Vous n'avez pas de marque-page
        </div>

        <div
          class="pt-8"
          v-show="
            bookmarksContext.bookmarks.length && !computedBookmarks.length
          "
        >
          Aucun marque-page ne correspond à cette recherche
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="!!bookmarksContext.bookmarkToDelete"
      @confirm="deleteBookmark"
      @cancel="bookmarksContext.bookmarkToDelete = null"
      title="Supprimer un marque-page"
      :message="`Etes-vous certain de vouloir supprimer le marque-page <span class='font-mono'>${
        bookmarksContext.bookmarkToDelete &&
        bookmarksContext.bookmarkToDelete.title
      }</span> ?`"
      confirm-btn-text="Supprimer"
      :confirm-btn-attrs="{ type: 'danger' }"
    />
  </div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import BookmarksSort from "./components/BookmarksSort.vue";
import BookmarksFilters from "./components/BookmarksFilters.vue";
import BookmarkItem from "./components/BookmarkItem.vue";
import { escapeRegExp } from "lodash";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  components: { ConfirmModal, BookmarksSort, BookmarksFilters, BookmarkItem },
  inject: ["bookmarksContext"],
  data: () => ({
    search: "",
    visibleSettingDrawer: "",
    sort: { by: "date", reverseOrder: false },
    filters: { tags: [], authors: [], dateRange: null, type: "ALL" }
  }),
  computed: {
    ...mapState(["app"]),
    isSorted: ({ sort: { by, reverseOrder } }) => by !== "date" || reverseOrder,
    isFiltered: ({ filters: { tags, authors, dateRange, type } }) =>
      tags[0] || authors[0] || dateRange || type !== "ALL",
    computedBookmarks: ({
      search,
      sort: { by, reverseOrder },
      filters,
      bookmarksContext: { bookmarks }
    }) => {
      const searchTerms = escapeRegExp(search).split(" ");
      const sortByMethods = {
        date: (
          { qMeta: { modifiedDate: a } },
          { qMeta: { modifiedDate: b } }
        ) => new Date(b) - new Date(a),
        name: ({ qMeta: { title: a } }, { qMeta: { title: b } }) =>
          a.localeCompare(b, undefined, {
            sensitivity: "base",
            ignorePunctuation: true
          }),
        author: (
          { qMeta: { author: a = "" } },
          { qMeta: { author: b = "" } }
        ) =>
          a.localeCompare(b, undefined, {
            sensitivity: "base",
            ignorePunctuation: true
          })
      };

      const computedBookmarks =
        // spread avoid sort side effect
        [...bookmarks]
          // newly created bookmarks doesn't have a modifiedDate (Qlik bug?)
          // as a workaround, we set the modified date as description
          .map(({ qMeta, qMeta: { description, modifiedDate }, ...rest }) => ({
            ...rest,
            qMeta: {
              ...qMeta,
              ...(description[0] === "{" ? JSON.parse(description) : {}),
              ...(modifiedDate && { modifiedDate })
            }
          }))
          // filter bookmarks by search terms (could be in title, author or tags)
          .filter(
            ({
              qMeta: { title, author = "", tags = [], modifiedDate, combined }
            }) => {
              const tagsMatch = filters.tags.every(tag => tags.includes(tag));
              const authorsMatch = filters.authors.length
                ? filters.authors.includes(author)
                : true;
              const dateRangeMatch = filters.dateRange
                ? new Date(filters.dateRange[0]) < new Date(modifiedDate) &&
                  new Date(modifiedDate) < new Date(filters.dateRange[1])
                : true;
              const combinedMatch =
                filters.type === "ALL"
                  ? true
                  : filters.type === "COMBINED"
                  ? combined
                  : !combined;
              const searcheableString = `${title} ${author} ${tags.join(" ")}`;
              const searchMatch = searchTerms.every(term =>
                RegExp(term, "i").test(searcheableString)
              );

              return (
                tagsMatch &&
                authorsMatch &&
                dateRangeMatch &&
                combinedMatch &&
                searchMatch
              );
            }
          )
          .sort(sortByMethods[by]);

      return reverseOrder ? computedBookmarks.reverse() : computedBookmarks;
    }
  },
  methods: {
    tagClick(tag) {
      this.filters.tags = [...new Set([...this.filters.tags, tag])];
      this.toggleVisibleSettingDrawer("filters");
    },
    toggleVisibleSettingDrawer(settingDrawer) {
      this.visibleSettingDrawer =
        this.visibleSettingDrawer === settingDrawer ? "" : settingDrawer;
    },
    deleteBookmark() {
      this.app.bookmark.remove(this.bookmarksContext.bookmarkToDelete.id);
      this.bookmarksContext.bookmarkToDelete = null;
    }
  }
};
</script>

<style>
.bookmarks-collapse.el-collapse {
  border: none;
}

.bookmarks-collapse .el-collapse-item__content {
  padding: 0;
}

.bookmarks-collapse.el-collapse > .el-collapse-item > div[role="tab"] {
  display: none;
}
</style>
