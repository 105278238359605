<template>
  <div class="l-frame-footer">
    <div class="o-footer h-full">
      <div class="o-footer-actions">
        <div class="m-buttonGroup m-buttonGroup--stacked">
          <a
            class="a-button cursor-pointer"
            @click="backCount && app.back()"
            :class="{ disabled: !backCount }"
          >
            <fa :icon="['fal', 'undo']" />
          </a>
          <a
            class="a-button cursor-pointer"
            @click="forwardCount && app.forward()"
            :class="{ disabled: !forwardCount }"
          >
            <fa :icon="['fal', 'redo']" />
          </a>
          <a
            class="a-button cursor-pointer"
            @click="
              selections.length &&
                (selectionState.clearAll(true),
                $mixpanel.track('Delete filters'))
            "
            :class="{ disabled: !selections.length }"
          >
            <fa :icon="['fal', 'times']" />
          </a>
          <a
            class="a-button cursor-pointer"
            @click="
              selections.length &&
                (toggleOpen('create'),
                $mixpanel.track('Save Bookmark Footer', {
                  numberOfFilters: selections.length
                }))
            "
            :class="{ disabled: !selections.length }"
          >
            <fa icon="save" />
          </a>
        </div>
      </div>
      <div class="o-footer-filters">
        <div class="m-filters">
          <div class="m-filters-label">Filtres</div>
          <div class="m-filters-area w-full">
            <QsSelectionBar />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QsSelectionBar from "@/components/Qlik/QsSelectionBar";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("qlik");
const { mapMutations: mapBookmarksDrawerMutations } = createNamespacedHelpers(
  "bookmarksDrawer"
);

export default {
  name: "Footer",
  components: { QsSelectionBar },
  data: () => ({
    selectionState: {},
    selections: [],
    backCount: 0,
    forwardCount: 0
  }),
  created() {
    const refreshSelectionState = ({ selections, backCount, forwardCount }) => {
      Object.assign(this.$data, { selections, backCount, forwardCount });
      selections.length && this.$mixpanel.track("Use filter");
    };

    this.selectionState = this.app.selectionState();
    this.selectionState.OnData.bind(() =>
      refreshSelectionState(this.selectionState)
    );
    refreshSelectionState(this.selectionState);
  },
  computed: mapState(["app"]),
  methods: mapBookmarksDrawerMutations(["toggleOpen"])
};
</script>

<style scoped>
.l-frame-footer {
  height: 49px;
  background: #f5f7fa;
}

.m-filters-area {
  padding: 0;
  align-items: center;
}

.disabled {
  color: #c0c4cc;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ebeef5;
}
</style>
