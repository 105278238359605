import producer from "./producer.js";
const { exportTypes, pages } = producer;

export default {
  exportTypes,
  pages: {
    ...{ ...pages, showsTemporality: null, showsSales: null },
    keyMetrics: {
      component: "festival/FestivalHomepage",
      label: "Indicateurs clés",
      icon: "home",
      order: 110
    },
    showsCashless: {
      component: "festival/FestivalShowsCashless",
      label: "Cashless",
      category: "shows",
      order: 309
    },
    festivalShowsTemporality: {
      component: "festival/FestivalShowsTemporality",
      label: "Jours & Éditions",
      category: "shows",
      order: 320
    },
    showsProgram: {
      component: "festival/FestivalShowsProgram",
      label: "Programmation",
      category: "shows",
      order: 329
    }
  }
};
