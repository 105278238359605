<template>
  <div class="kpi-container">
    <div v-if="!loading" class="kpi-object">
      <el-tooltip
        :content="kpi.title"
        placement="bottom"
        :disabled="!titleAsTooltip || !kpi.title"
      >
        <span
          class="kpi-object-value"
          :class="{ 'with-tooltip': titleAsTooltip }"
        >
          {{ kpi.value }}
          <span
            v-show="percentageStatus !== 'neutral'"
            class="kpi-object-percentage"
            :class="percentageStatus"
          >
            {{ kpi.percentage }}
          </span>
        </span>
      </el-tooltip>
      <div v-if="!titleAsTooltip && kpi.title" class="kpi-object-title">
        {{ kpi.title }}
      </div>
    </div>
    <spinner v-else></spinner>
    <span :id="qlikDomId" style="display: none" />
  </div>
</template>

<script>
import { getQlikMetadata } from "@/config/qlikMetadata.js";
import { get, uniqueId } from "lodash";
import Spinner from "@/components/Spinner";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters } = createNamespacedHelpers("qlik");

export default {
  components: { Spinner },
  props: {
    qlikId: { type: String, default: "", required: true },
    title: { type: String, default: "" },
    titleAsTooltip: { type: Boolean, default: false },
    preserveOnDestroy: { type: Boolean, default: false }
  },
  data: () => ({ qlikObject: null, loading: true }),
  mounted() {
    this.app.getObject(this.qlikDomId, this.qlikId).then(qlikObject => {
      qlikObject.Invalidated.bind(() => (this.loading = true));
      qlikObject.Validated.bind(() => (this.loading = false));
      Object.assign(this.$data, { qlikObject, loading: false });
    });
  },
  computed: {
    ...mapState(["app"]),
    ...mapGetters(["appLoaded"]),
    qlikDomId: () => `q${uniqueId()}`,
    kpi() {
      if (this.loading) return {};

      const kpi = {
        value: get(
          this.qlikObject,
          ["layout", "qHyperCube", "qDataPages", 0, "qMatrix", 0, 0, "qText"],
          0
        ),
        percentage: get(
          this.qlikObject,
          ["layout", "qHyperCube", "qDataPages", 0, "qMatrix", 0, 1, "qText"],
          0
        ),
        title: this.title || getQlikMetadata(this.qlikId).title
      };

      this.$emit("data", kpi);
      return kpi;
    },
    percentageStatus() {
      if (/\+[0-9,]+%/.test(this.kpi.percentage)) return "positive";
      if (/-[0-9,]+%/.test(this.kpi.percentage)) return "negative";
      return "neutral";
    }
  },
  beforeDestroy() {
    if (!this.qlikObject || this.preserveOnDestroy) return;
    this.appLoaded &&
      this.app
        .destroySessionObject(this.qlikId)
        .then(() => this.qlikObject.close())
        .then(() => (this.qlikObject = null));
  }
};
</script>

<style>
.kpi-container {
  text-align: center;
}

.kpi-object {
  color: rgb(73, 80, 87);
}

.kpi-object-title {
  text-align: center;
  font-size: 110%;
}

.kpi-object-value {
  font-weight: 600;
  font-size: 190%;
}

.kpi-object-value.with-tooltip {
  cursor: pointer;
}

.kpi-object-percentage {
  font-size: 60%;
  vertical-align: top;
}

.negative {
  color: #cd201f;
}

.positive {
  color: #5eba00;
}
</style>
