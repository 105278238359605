<template>
  <div class="m-counter-item">
    <div class="m-counter-label">{{ title }}</div>
    <el-tooltip
      v-if="tooltip"
      class="tooltip"
      effect="dark"
      :content="tooltip"
      placement="bottom"
    >
      <div>
        <div
          v-for="(qlikId, index) in qlikIds"
          :key="index"
          class="m-counter-number"
        >
          {{ values[qlikId] }}
        </div>
      </div>
    </el-tooltip>
    <template v-else>
      <div
        v-for="(qlikId, index) in qlikIds"
        :key="index"
        class="m-counter-number"
      >
        {{ values[qlikId] }}
      </div>
    </template>

    <div
      v-for="qlikId in qlikIds"
      :key="qlikId"
      class="kpi-hidden"
      :id="uniqueId(qlikId)"
    ></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  props: {
    qlikIds: Array,
    cleanOnDestroy: {
      type: Boolean,
      default: true
    },
    title: String,
    tooltip: String
  },
  data() {
    return { values: {}, objectModels: {} };
  },
  computed: mapState(["app"]),
  mounted() {
    this.qlikIds.map(qlikId => {
      this.mountQlikObject(qlikId);
    });
  },
  methods: {
    uniqueId(qlikId) {
      return qlikId + this._uid;
    },
    mountQlikObject(qlikId) {
      this.app.getObject(this.uniqueId(qlikId), qlikId).then(objectModel => {
        objectModel.Validated.bind(this.updateData(qlikId));
        this.objectModels[qlikId] = objectModel;
        this.updateData(qlikId)();
      });
    },
    updateData(qlikId) {
      return () => {
        const data = this.objectModels[qlikId].layout.qHyperCube.qDataPages[0]
          .qMatrix[0][0].qText;
        this.values = Object.assign({}, this.values, {
          [qlikId]: data
        });
      };
    }
  }
};
</script>

<style scoped>
.m-counter-item {
  min-width: 108.37px;
}

.kpi-hidden {
  display: none;
}

.m-counter-number {
  font-size: 1.15rem;
  font-weight: 700;
}

.tooltip {
  cursor: help;
}
</style>
