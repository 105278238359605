import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import auth from "./modules/auth";
import qlik from "./modules/qlik";

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence({ modules: ["auth"] });

export default new Vuex.Store({
  modules: { auth, qlik },
  plugins: [vuexPersistence.plugin]
});
