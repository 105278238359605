<template>
  <el-form class="py-4" label-position="left" label-width="20%">
    <el-form-item label="Tags">
      <el-select
        v-model="tags"
        multiple
        filterable
        clearable
        placeholder="Choisissez un ou plusieurs tags"
        class="w-full"
      >
        <el-option v-for="tag in availableTags" :key="tag" :value="tag" />
      </el-select>
    </el-form-item>
    <el-form-item label="Auteurs">
      <el-select
        v-model="authors"
        multiple
        filterable
        clearable
        placeholder="Choisissez un ou plusieurs auteurs"
        class="w-full"
      >
        <el-option
          v-for="author in availableAuthors"
          :key="author"
          :value="author"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Dates">
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        :picker-options="pickerOptions"
        range-separator="à"
        start-placeholder="Date de début"
        end-placeholder="Date de fin"
        format="dd/MM/yyyy"
        style="width: 100%"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="Type">
      <div class="text-left">
        <el-radio-group v-model="type" class="whitespace-no-wrap">
          <el-radio-button label="ALL">Tous</el-radio-button>
          <el-radio-button label="NOT_COMBINED"> Marque-page </el-radio-button>
          <el-radio-button label="COMBINED"> Combinaison </el-radio-button>
        </el-radio-group>
      </div>
    </el-form-item>
    <div class="text-center">
      <el-link icon="el-icon-close" @click="reset">
        Effacer les filtres
      </el-link>
    </div>
  </el-form>
</template>

<script>
const getBookmarkTags = ({ qMeta: { description = "" } = {} } = {}) =>
  description[0] === "{" ? JSON.parse(description).tags : [];

const getBookmarkAuthor = ({ qMeta: { description = "" } = {} } = {}) =>
  description[0] === "{" ? JSON.parse(description).author : "";

export default {
  props: { value: { type: Object, required: true } },
  inject: ["bookmarksContext"],
  computed: {
    availableTags: ({ bookmarksContext: { bookmarks } }) =>
      new Set(bookmarks.flatMap(getBookmarkTags)),
    availableAuthors: ({ bookmarksContext: { bookmarks } }) =>
      new Set(bookmarks.map(getBookmarkAuthor).filter(Boolean)),
    pickerOptions() {
      return {
        disabledDate: date => date > Date.now(),
        shortcuts: [
          {
            text: "Semaine (7j)",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, new Date()]);
            }
          },
          {
            text: "Mois (30j)",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, new Date()]);
            }
          },
          {
            text: "3 mois (90j)",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, new Date()]);
            }
          }
        ]
      };
    },
    // v-model support: generate getter/setter for each field
    ...["tags", "authors", "dateRange", "type"].reduce(
      (acc, field) => ({
        ...acc,
        [field]: {
          get: ({ value }) => value[field],
          set(value) {
            this.$emit("input", { ...this.value, [field]: value });
          }
        }
      }),
      {}
    )
  },
  methods: {
    reset() {
      this.$emit("input", {
        tags: [],
        authors: [],
        dateRange: null,
        type: "ALL"
      });
    }
  }
};
</script>

<style scoped></style>
