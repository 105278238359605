<template>
  <div class="flex-shrink-0">
    <div class="o-header">
      <ul class="m-breadcrumbs">
        <li
          class="m-breadcrumbs-item"
          v-for="{ name, meta: { label } } in $route.matched"
          :key="name"
        >
          <span class="m-breadcrumbs-link">{{ label }}</span>
        </li>
      </ul>
      <div class="m-counter" v-if="appLoaded">
        <counter
          v-if="appLoaded && !hasTag('ED360')"
          key="ARmznwA"
          title="Nombre de contacts"
          qlik-id="ARmznwA"
          preserve-on-destroy
        />
        <counter
          v-if="hasTag('ED360')"
          key="KTaFf"
          title="Acheteurs"
          qlik-id="KTaFf"
          preserve-on-destroy
        />
        <counter title="Billets" qlik-id="QRNejcq" preserve-on-destroy />
        <multi-counter
          title="Période d'achat"
          :qlik-ids="['tBxH', 'jCcGTvd']"
          preserve-on-destroy
          :tooltip="purchasePeriodFull"
        />
        <qs-kpi
          class="display-none"
          qlik-id="dkqek"
          @data="updatePurchasePeriodStartDate"
        />
        <qs-kpi
          class="display-none"
          qlik-id="yMdTpN"
          @data="updatePurchasePeriodEndDate"
        />
      </div>
      <div class="m-buttonGroup">
        <el-tooltip
          v-show="app.qDocName && appList.length > 1"
          content="Liste de vos applications"
          placement="bottom"
        >
          <a class="a-button p-4" @click="$router.push('/').catch(() => {})">
            <fa icon="list-ul" class="mr-3" />
            {{ app.qDocName }}
          </a>
        </el-tooltip>

        <el-tooltip placement="bottom" v-if="app.template === 'festival'">
          <div slot="content">
            Lien cashless-billetterie
            <br />
            <br />
            Ce bouton permet d'analyser ce qui est lié aux commandes
            sélectionnées, <br />c'est-à-dire le cashless, le contrôle d'accès
            et l'achat de billets.
          </div>
          <qs-select-possible-header-button
            v-if="appLoaded"
            field="delight_order_id"
            :icon="['far', 'link']"
          />
        </el-tooltip>

        <el-tooltip placement="bottom" v-if="appLoaded">
          <div slot="content">
            Activité des contacts
            <br />
            <br />
            Ce bouton permet de sélectionner les contacts qui sont dans vos
            filtres actuels, <br />afin de voir leur activité dans toute votre
            base.
          </div>

          <qs-select-possible-header-button
            :field="
              hasTag('ED360') ? 'customer_provider_id' : '[Email du spectateur]'
            "
            :icon="['far', 'user']"
          />
        </el-tooltip>

        <el-tooltip content="Marque-pages" placement="bottom">
          <a @click="toggleOpen()" class="a-button">
            <fa :icon="['fal', 'bookmark']" size="lg" fixed-width />
          </a>
        </el-tooltip>

        <ExportsModal v-if="appLoaded" class="a-button" />

        <el-tooltip content="Documentation" placement="bottom-end">
          <a
            class="a-button"
            v-if="theme.displayDocumentationButton"
            target="_blank"
            href="https://intercom.help/delight/en"
          >
            <fa :icon="['fal', 'question-circle']" size="lg" fixed-width />
          </a>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "@/components/Qlik/Counter";
import MultiCounter from "@/components/Qlik/MultiCounter";
import QsKpi from "@/components/Qlik/QsKpi";
import QsSelectPossibleHeaderButton from "@/components/Qlik/QsSelectPossibleHeaderButton";
import operator from "@/config/operator.js";
import ExportsModal from "@/layout/ExportsModal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters } = createNamespacedHelpers("qlik");
const { mapMutations: mapBookmarksDrawerMutations } = createNamespacedHelpers(
  "bookmarksDrawer"
);

export default {
  name: "Header",
  components: {
    Counter,
    MultiCounter,
    QsKpi,
    QsSelectPossibleHeaderButton,
    ExportsModal
  },
  data: () => ({ purchasePeriodStartDate: null, purchasePeriodEndDate: null }),
  computed: {
    ...mapState(["app", "appList"]),
    ...mapGetters(["appLoaded", "hasTag"]),
    theme: () => operator.theme,
    purchasePeriodFull() {
      return `Du ${this.purchasePeriodStartDate} au ${this.purchasePeriodEndDate}`;
    }
  },
  methods: {
    ...mapBookmarksDrawerMutations(["toggleOpen"]),
    updatePurchasePeriodStartDate({ value }) {
      this.purchasePeriodStartDate = value;
    },
    updatePurchasePeriodEndDate({ value }) {
      this.purchasePeriodEndDate = value;
    }
  }
};
</script>

<style scoped>
.o-header {
  height: 5.8rem;
}
.a-button {
  cursor: pointer;
}

.display-none {
  display: none;
}
</style>
