import { escapeRegExp } from "lodash";

import qlikConfig from "@/config/qlik.js";
import enigma from "enigma.js";
import schema from "enigma.js/schemas/12.20.0.json";
import store from "@/store";

// Qlik API max page size is 10 000
const QLIK_PAGE_SIZE = 10000;
const EMAIL_FIELD = "Email du spectateur";

export const getBookmarkTags = ({ qMeta: { description = "" } = {} } = {}) =>
  description[0] === "{" ? JSON.parse(description).tags : [];

const findBookmark = (names, bookmarks) =>
  bookmarks.find(({ qMeta: { title } }) => names.includes(title));

export const existingBookmark = ({ operation, nbChunks, name, bookmarks }) => {
  if (operation !== "split") return findBookmark([name], bookmarks);

  const names = new Array(nbChunks).fill().map((_, idx) => `${name}${++idx}`);
  return findBookmark(names, bookmarks);
};

const EMAIL_LIST_QUERY = {
  qInfo: { qType: "ListObject" },
  qListObjectDef: {
    qDef: {
      qFieldDefs: [`=[${EMAIL_FIELD}]`],
      qFieldLabels: [EMAIL_FIELD],
      qSortCriterias: [{ qSortByState: 1 }]
    },
    qInitialDataFetch: [{ qHeight: 1, qLeft: 0, qTop: 0, qWidth: 1 }],
    qLibraryId: "",
    qStateName: "$"
  }
};

let qlikDoc = null;

const getQlikDoc = async () => {
  const { qDocId } = store.state.qlik.app;

  // If we're still in the same qlik app as previously, we recycle
  if (qlikDoc?.id === qDocId) return qlikDoc;

  const { host, prefix } = qlikConfig;

  qlikDoc = await enigma
    .create({
      schema,
      url: `wss://${host}${prefix}app/${qDocId}`,
      createSocket: url => new WebSocket(url)
    })
    .open()
    .then(global => global.openDoc(qDocId));

  return qlikDoc;
};

const shuffle = arr =>
  arr.forEach((_, i) => {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  });

export const publishBookmark = async id => {
  const qlikDoc = await getQlikDoc();

  qlikDoc.getBookmark(id).then(bookmark => bookmark.publish());
};

const fetchAllSelectedEmails = async () => {
  const qlikDoc = await getQlikDoc();

  const emailList = await qlikDoc.createSessionObject(EMAIL_LIST_QUERY);

  let page = 0;
  const selectedElements = [];
  // Paginated fetch of all selected indexes of the field
  do {
    const list = await emailList.getListObjectData({
      qPath: "/qListObjectDef",
      qPages: [
        {
          qHeight: QLIK_PAGE_SIZE,
          qLeft: 0,
          qTop: page * QLIK_PAGE_SIZE,
          qWidth: 1
        }
      ]
    });

    var loopResult = list[0].qMatrix.reduce(
      (acc, elem) =>
        elem[0].qState === "S" ? [...acc, elem[0].qElemNumber] : acc,
      []
    );
    page++;
    selectedElements.push(...loopResult);
  } while (loopResult.length === QLIK_PAGE_SIZE);

  return selectedElements;
};

export const splitIntoBookmarks = async ({ nbChunks, name, tags }) => {
  const { app } = store.state.qlik;
  const field = app.field(EMAIL_FIELD);

  await field.selectPossible(true);

  await field.clearOther(true);

  const selectedElements = await fetchAllSelectedEmails();

  shuffle(selectedElements);

  const chunkSize = Math.ceil(selectedElements.length / nbChunks);

  for (let i = 0; i < nbChunks; i++) {
    const chunk = selectedElements.slice(i * chunkSize, (i + 1) * chunkSize);
    await field.select(chunk, false, true);
    await app.bookmark.create(
      `${name}${i + 1}`,
      JSON.stringify({
        modifiedDate: new Date().toISOString(),
        author: store.state.auth.user.name,
        combined: true,
        tags: tags
      })
    );
  }

  // We did nbChunk + 2 modifications to selections, so let's go back
  for (let i = 0; i < nbChunks + 2; i++) {
    await app.back();
  }
};
