<template>
  <div
    @click="
      app.bookmark.apply(id) && (toggleOpen(), $mixpanel.track('Use bookmark'))
    "
    class="p-4 rounded-lg hover:bg-blue-50 cursor-pointer text-2xl"
  >
    <div class="flex items-center">
      <div class="flex-shrink-0" :class="{ '-ml-1': combined }">
        <el-tooltip
          :disabled="!combined"
          content="Issu d'une combinaison de marque-pages"
          placement="left"
        >
          <span>
            <fa
              :icon="['fal', 'bookmark']"
              size="lg"
              fixed-width
              :class="{ '-mt-2': combined }"
            />
            <fa
              v-show="combined"
              icon="bookmark"
              size="lg"
              fixed-width
              class="-ml-8 -mb-2 text-blue-400"
            />
          </span>
        </el-tooltip>
      </div>
      <div
        class="flex-grow text-gray-800 text-left px-2"
        :class="{ '-ml-1': combined }"
      >
        <div>{{ title }}</div>
        <div class="text-xl text-gray-600 pt-2">
          {{ modifiedDate | toLocaleString }}
          <span v-show="author"> | {{ author }}</span>
        </div>
        <div class="flex flex-wrap">
          <el-tag
            v-for="tag in tags"
            :key="tag"
            size="mini"
            class="ml-1 mt-1"
            @click.stop="$emit('tagClick', tag)"
          >
            <fa icon="tag" class="mr-1" />
            {{ tag }}
          </el-tag>
        </div>
      </div>
      <div class="flex-shrink-0">
        <el-button
          @click.stop="bookmarksContext.bookmarkToEdit = { id, title, tags }"
          icon="el-icon-edit"
          size="small"
          plain
          circle
        ></el-button>
        <el-button
          @click.stop="
            (bookmarksContext.bookmarkToDelete = { id, title }),
              $mixpanel.track('Delete bookmark')
          "
          style="margin-left: 0.5rem"
          type="danger"
          icon="el-icon-delete"
          size="small"
          plain
          circle
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState: mapQlikState } = createNamespacedHelpers("qlik");
const { mapMutations: mapBookmarksDrawerMutations } = createNamespacedHelpers(
  "bookmarksDrawer"
);

export default {
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    modifiedDate: { type: String, required: true },
    author: { type: String, default: "" },
    combined: { type: Boolean, default: false },
    tags: { type: Array, default: () => [] }
  },
  inject: ["bookmarksContext"],
  computed: mapQlikState(["app"]),
  methods: mapBookmarksDrawerMutations(["toggleOpen"])
};
</script>

<style scoped></style>
