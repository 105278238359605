<template>
  <div>
    <el-form
      label-position="top"
      @submit.native.prevent="submit"
      class="text-left"
      :disabled="loading"
    >
      <el-alert
        type="warning"
        v-show="createCombined && reachedCombinedLimit"
        :closable="false"
        >Vous avez atteint le maximum de 10 marque-pages issus d'une combinaison
        de marque-pages
      </el-alert>
      <el-form-item
        v-show="bookmarksContext.bookmarks.length && !bookmarkToEdit"
        label="Créer ou remplacer"
      >
        <el-radio-group v-model="operation" @change="name = ''" class="-mt-1">
          <el-radio
            :disabled="createCombined && reachedCombinedLimit"
            label="create"
            class="my-1"
          >
            Créer un nouveau marque-page
          </el-radio>
          <el-radio label="replace" class="my-1">
            Remplacer un marque-page existant
          </el-radio>
          <el-radio label="split" class="my-1">
            Répartir les contacts entre plusieurs marque-pages
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-show="operation === 'create'" label="Nom">
        <el-input v-model="name" placeholder="Mon marque-page"></el-input>
      </el-form-item>
      <el-form-item
        v-show="operation === 'replace'"
        label="Marque-page à remplacer"
      >
        <el-select filterable v-model="name" class="w-full">
          <el-option
            v-for="{ qInfo: { qId }, qMeta: { title } } in createCombined &&
            reachedCombinedLimit
              ? combinedBookmarks
              : bookmarks"
            :key="qId"
            :value="title"
          />
        </el-select>
      </el-form-item>

      <el-alert
        v-show="operation === 'split'"
        type="info"
        :closable="true"
        center
        effect="dark"
      >
        Cette opération va prendre les contacts de votre sélection actuelle et
        les répartir aléatoirement entre plusieurs marque-pages qui seront nommé
        suivant le préfixe indiqué. Attention : cette opération peut prendre
        quelques minutes si votre sélection inclut beaucoup de contacts.
      </el-alert>
      <el-form-item
        v-show="operation === 'split'"
        label="Préfixe des marque-pages"
      >
        <el-input v-model="name" placeholder="Mon préfixe" />
      </el-form-item>

      <el-form-item label="Tags">
        <el-select
          v-model="tags"
          class="w-full"
          multiple
          filterable
          clearable
          allow-create
          default-first-option
          placeholder="Choisissez les tags de votre marque-page"
        >
          <el-option v-for="tag in availableTags" :key="tag" :value="tag">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-show="operation === 'split'"
        label="Nombre de marque-pages"
      >
        <el-input-number v-model="nbChunks" :min="2" :max="10" />
      </el-form-item>

      <el-button
        type="primary"
        :disabled="!name || (existingBookmark && operation === 'split')"
        :loading="loading"
        native-type="submit"
      >
        <span v-if="existingBookmark && operation === 'split'">
          Le marque-page {{ existingBookmark.qMeta.title }} existe déjà
        </span>
        <span
          v-else-if="
            existingBookmark &&
            (!bookmarkToEdit ||
              bookmarkToEdit.id !== existingBookmark.qInfo.qId)
          "
        >
          <fa icon="exclamation-triangle" />
          Remplacer
        </span>
        <span v-else>
          {{ bookmarkToEdit ? "Sauvegarder" : "Ajouter" }}
        </span>
      </el-button>
      <el-button @click="$emit('close')">Annuler</el-button>
    </el-form>

    <ConfirmModal
      :open="confirmModalOpen"
      @confirm="replaceBookmark"
      @cancel="confirmModalOpen = false"
      title="Remplacer le contenu d'un marque-page"
      :message="`Etes-vous certain de vouloir remplacer le contenu du marque-page <span class='font-mono'>${name}</span> avec les filtres actuellement sélectionnés ?`"
      confirm-btn-text="Remplacer"
    />
  </div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";

import { createNamespacedHelpers } from "vuex";
import {
  getBookmarkTags,
  existingBookmark,
  publishBookmark,
  splitIntoBookmarks
} from "./bookmarks.const.js";

const { mapState: mapQlikState } = createNamespacedHelpers("qlik");
const { mapState: mapAuthState } = createNamespacedHelpers("auth");
const { mapState: mapBookmarksDrawerState } = createNamespacedHelpers(
  "bookmarksDrawer"
);

export default {
  components: { ConfirmModal },
  props: { bookmarkToEdit: { type: Object, default: () => ({}) } },
  data: ({ bookmarkToEdit }) => ({
    name: bookmarkToEdit?.title ?? "",
    addedTags: bookmarkToEdit?.tags ?? [],
    removedTags: [],
    confirmModalOpen: false,
    operation: "create",
    loading: false,
    nbChunks: 2
  }),
  inject: ["bookmarksContext"],
  mounted() {
    const { createCombined, reachedCombinedLimit } = this;
    if (createCombined && reachedCombinedLimit) this.operation = "replace";
  },
  computed: {
    ...mapQlikState(["app"]),
    ...mapAuthState(["user"]),
    ...mapBookmarksDrawerState(["createCombined"]),
    bookmarks: ({ bookmarksContext: { bookmarks } }) =>
      bookmarks
        // newly created bookmarks doesn't have a modifiedDate (Qlik bug?)
        // as a workaround, we set the modified date as description
        .map(({ qMeta, qMeta: { description, modifiedDate }, ...rest }) => ({
          ...rest,
          qMeta: {
            ...qMeta,
            ...(description[0] === "{" ? JSON.parse(description) : {}),
            ...(modifiedDate && { modifiedDate })
          }
        }))
        .sort(
          (
            // newly created bookmarks doesn't have a modifiedDate (Qlik bug?)
            { qMeta: { modifiedDate: firstDate = Date.now() } },
            { qMeta: { modifiedDate: secondDate } }
          ) => new Date(secondDate) - new Date(firstDate)
        ),
    existingBookmark: ({
      bookmarksContext: { bookmarks },
      nbChunks,
      name,
      operation
    }) => existingBookmark({ operation, nbChunks, name, bookmarks }),
    combinedBookmarks: ({ bookmarks }) =>
      bookmarks.filter(({ qMeta: { combined } }) => combined),
    reachedCombinedLimit: ({ combinedBookmarks }) =>
      combinedBookmarks.length >= 10,
    availableTags: ({ bookmarksContext: { bookmarks } }) =>
      new Set(bookmarks.flatMap(getBookmarkTags)),
    assignedTags: ({ existingBookmark, bookmarkToEdit }) => [
      ...new Set([
        ...getBookmarkTags(existingBookmark),
        ...(bookmarkToEdit?.tags ?? [])
      ])
    ],
    tags: {
      get: ({ addedTags, removedTags, assignedTags }) => [
        ...new Set(
          assignedTags.length
            ? [...assignedTags, ...addedTags].filter(
                tag => !removedTags.includes(tag)
              )
            : addedTags
        )
      ],
      set(tags) {
        let addedTags = [];
        let removedTags = [];

        if (this.assignedTags.length) {
          this.assignedTags.forEach(tag => {
            tags.includes(tag) || removedTags.push(tag);
          });
          tags.forEach(tag => {
            this.assignedTags.includes(tag) || addedTags.push(tag);
          });
        } else {
          addedTags = tags;
        }

        Object.assign(this.$data, { removedTags, addedTags });
      }
    }
  },
  methods: {
    async submit() {
      if (!this.name) return;
      const {
        existingBookmark,
        bookmarkToEdit,
        createBookmark,
        editBookmark,
        splitIntoBookmarks
      } = this;

      if (
        existingBookmark &&
        (!bookmarkToEdit || bookmarkToEdit.id !== existingBookmark.qInfo.qId)
      ) {
        return (this.confirmModalOpen = true);
      }

      this.loading = true;
      bookmarkToEdit
        ? await editBookmark()
        : this.operation === "split"
        ? await splitIntoBookmarks()
        : await createBookmark();
      this.close();
    },
    async replaceBookmark() {
      this.loading = true;
      const { qId } = this.existingBookmark.qInfo;
      const { bookmarkToEdit, editBookmark, createBookmark } = this;

      await this.app.bookmark.remove(qId);
      bookmarkToEdit ? await editBookmark() : await createBookmark();

      this.close();
    },
    async editBookmark() {
      const { id } = this.bookmarkToEdit;

      await this.app.bookmark.apply(id);
      await this.app.bookmark.remove(id);
      await this.createBookmark();
      await this.app.back();

      this.close();
    },
    async createBookmark() {
      const { length: userBookmarksCount } = this.bookmarks.filter(
        ({ qMeta: { author, combined } }) =>
          !combined && (author === this.user?.name ?? "")
      );
      const { length: userCombinationBookmarksCount } = this.bookmarks.filter(
        ({ qMeta: { author, combined } }) =>
          combined && (author === this.user?.name ?? "")
      );
      this.$mixpanel.track(
        `Save bookmark${this.createCombined ? " combination" : ""}`,
        {
          tagsCount: this.tags.length,
          ...(this.createCombined
            ? { userCombinationBookmarksCount }
            : { userBookmarksCount })
        }
      );
      const bookmark = await this.app.bookmark.create(
        this.name,
        JSON.stringify({
          modifiedDate: new Date().toISOString(),
          author: this.user?.name ?? "",
          combined: this.createCombined,
          tags: this.tags
        })
      );
      return publishBookmark(bookmark.id);
    },
    splitIntoBookmarks() {
      return splitIntoBookmarks(this)
        .then(() =>
          this.$mixpanel.track(`Split into bookmarks`, {
            product: "Ignition",
            prefix: this.name,
            tagsCount: this.tags.length,
            bookmarkCount: this.nbChunks
          })
        )
        .catch(this.handleError);
    },
    close() {
      this.loading = false;
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
