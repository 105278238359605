import Vue from "vue";
import VueMixpanel from "vue-mixpanel";
import vm from "@/main.js";

const { VUE_APP_MIXPANEL_TOKEN, NODE_ENV } = process.env;

export const setMixpanelUser = ({ id, name, email, tenantName }) => {
  if (NODE_ENV !== "production" || /@delight-data\.com$/.test(email)) return;
  vm.$mixpanel.people.set({ $email: email, $name: name, tenantName });
  vm.$mixpanel.identify(id);
  vm.$mixpanel.opt_in_tracking();
  vm.$mixpanel.track("Login Success");
};

Vue.use(VueMixpanel, {
  token: VUE_APP_MIXPANEL_TOKEN,
  config: {
    api_host: "https://api-eu.mixpanel.com",
    opt_out_tracking_by_default: true
  }
});
