<template>
  <a class="a-button" @click="filter">
    <fa :icon="$props.icon" />
  </a>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  props: { field: String, icon: Array },
  computed: mapState(["app"]),
  methods: {
    filter() {
      this.app.field(this.field).selectPossible();
      this.app.field(this.field).clearOther();
      document.activeElement.blur();
    }
  }
};
</script>

<style scoped>
.a-button {
  cursor: pointer;
}
</style>
