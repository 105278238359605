import producer from "./producer.js";
const { pages } = producer;

export default {
  exportTypes: ["buyersWithEmails", "shows", "pros"],
  pages: {
    ...{ ...pages, publicNewsletters: null },
    keyMetrics: {
      component: "ticketing/TicketingHomepage",
      label: "Indicateurs clés",
      icon: "home",
      order: 110
    },
    showsProfile: {
      component: "producer/ProducerShowsProfile",
      label: "Portrait",
      category: "shows",
      order: 308
    },
    showsPros: {
      component: "ticketing/TicketingShowsPros",
      label: "Professionnels",
      category: "shows",
      order: 341
    },
    expertSteering: {
      component: "ticketing/TicketingExpertSteering",
      label: "Pilotage",
      category: "expert",
      order: 421
    }
  }
};
