export default {
  full: {
    pages: {
      expertSteering: {
        component: "ticketing/TicketingExpertSteering",
        label: "Pilotage",
        category: "expert",
        order: 425
      }
    }
  },
  ED360: {
    exportTypes: ["buyersIds"],
    pages: {
      // When expert recurrence will go to prod, ED360 will not have it
      expertRecurrence: null,
      expertSegmentManagement: null,
      publicNewsletters: null,
      publicOptin: null
    }
  },
  no_newsletters: {
    pages: { publicNewsletters: null }
  },
  eventTypes: {
    pages: {
      showsProfile: {
        component: "producer/ProducerShowsProfile",
        label: "Portrait",
        category: "shows",
        order: 308
      }
    }
  },
  activetrail: {
    exportTypes: ["activetrail"]
  },
  access_control_producer: {
    pages: {
      AccessControl: {
        component: "producer/ProducerAccessControl",
        label: "Contrôle d'accès",
        category: "shows",
        order: 333
      }
    }
  },
  access_control_museum: {
    pages: {
      AccessControl: {
        component: "museum/MuseumAccessControl",
        label: "Contrôle d'accès",
        category: "shows",
        order: 333
      }
    }
  },
  access_control_sport: {
    pages: {
      AccessControl: {
        component: "sport/SportAccessControl",
        label: "Contrôle d'accès",
        category: "shows",
        order: 333
      }
    }
  }
};
