import Vue from "vue";

Vue.filter("toLocaleString", date =>
  new Date(date).toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  })
);
