import templates from "./index.js";
import categories from "./categories.js";
import tags from "./tags.js";
import exportTypes from "./exportTypes.js";

export const getRoutesFromPages = pages => {
  const routesByCategories = Object.entries(pages)
    .filter(([, value]) => value && value.category)
    .sort(
      ([, { order: firstOrder }], [, { order: secondOrder }]) =>
        firstOrder - secondOrder
    )
    .reduce(
      (acc, [key, { component, category, ...meta }]) => ({
        ...acc,
        [category]: [
          ...(acc[category] || []),
          {
            path: key,
            name: key,
            component: () => import(`@/pages/${component}.vue`),
            meta
          }
        ]
      }),
      {}
    );

  return [
    ...Object.entries(pages)
      .filter(([, value]) => value && !value.category)
      .sort(
        ([, { order: firstOrder }], [, { order: secondOrder }]) =>
          firstOrder - secondOrder
      )
      .map(([key, { component, ...meta }]) => ({
        path: `/${key}`,
        name: key,
        component: () => import(`@/pages/${component}.vue`),
        meta
      })),
    ...Object.entries(routesByCategories).map(([key, value]) => ({
      path: `/${key}`,
      name: key,
      component: { render: h => h("router-view") },
      meta: categories[key],
      children: value
    }))
  ];
};

export const getConsolidatedAppConfig = ({
  template = "producer",
  tags: appTags = [],
  customPages = {}
}) => {
  const { exportTypes: templateExportTypes, pages } = templates[template];
  const {
    pages: tagPages = {},
    exportTypes: tagExportTypes = []
  } = appTags.reduce((acc, tag) => ({ ...acc, ...(tags[tag] || {}) }), {});
  const consolidatedExportTypes = [
    ...templateExportTypes,
    ...tagExportTypes
  ].reduce(
    (acc, exportType) => ({
      ...acc,
      [exportTypes[exportType].overwrite || exportType]: exportTypes[exportType]
    }),
    {}
  );
  const routes = getRoutesFromPages({ ...pages, ...tagPages, ...customPages });

  return {
    template,
    tags: appTags,
    exportTypes: consolidatedExportTypes,
    routes
  };
};
