export const getQlikMetadata = qlikId => {
  const metadata = { ...defaultQlikMetadata, ...qlikMetadata[qlikId] };
  const { tooltip, insight, ...rest } = metadata;

  return {
    tooltip: tooltip.replace(/\n/g, "<br />"),
    insight: insight.replace(/\n/g, "<br />"),
    ...rest
  };
};

export const defaultQlikMetadata = {
  tooltip: "",
  insight: "",
  aggregKpi: { qlikId: "", title: "" },
  fillingRateQlikId: "",
  title: "",
  // See here for available export options:
  // https://help.qlik.com/en-US/sense-developer/June2019/Subsystems/APIs/Content/Sense_ClientAPIs/CapabilityAPIs/VisualizationAPI/QVisualization.htm
  exports: {
    csv: { format: "CSV_C" },
    xlsx: { format: "OOXML" },
    jpg: { format: "JPG", height: 600, width: 800 },
    pdf: { documentSize: "a4", orientation: "landscape", aspectRatio: 2 }
  }
};

export const qlikMetadata = {
  aPLkdAQ: {
    tooltip:
      "Nombre de billets vendus au cours des 7 derniers jours.\nLa comparaison se fait avec les 7 mêmes jours de l'année passée.",
    title: "Billets cette semaine"
  },
  vxfAJ: {
    tooltip:
      "Nombre de billets vendus au cours des 30 derniers jours.\nLa comparaison se fait avec les 30 mêmes jours de l'année passée.",
    title: "Billets ce mois-ci"
  },
  PtbyG: {
    tooltip:
      "Nombre de billets vendus depuis le début de l'année calendaire en cours.\nLa comparaison est faite avec la même période un an plus tôt.",
    title: `Billets en ${new Date().getFullYear()}`
  },
  MLxWVH: {
    tooltip:
      "Nombre d'acheteurs au cours des 7 derniers jours.\nLa comparaison se fait avec les 7 mêmes jours de l'année passée.",
    title: "Acheteurs cette semaine"
  },
  zNsm: {
    tooltip:
      "Nombre d'acheteurs au cours des 30 derniers jours.\nLa comparaison se fait avec les 30 mêmes jours de l'année passée.",
    title: "Acheteurs ce mois-ci"
  },
  XVKSbT: {
    tooltip:
      "Nombre d'acheteurs depuis le début de l'année calendaire en cours.\nLa comparaison est faite avec la même période un an plus tôt.",
    title: `Acheteurs en ${new Date().getFullYear()}`
  },
  JqHTVpW: {
    tooltip:
      "La taille des carrés est fonction du nombre de billets commandés : plus le carré est grand, plus il y a eu de billets commandés. \nLa couleur des carrés est fonction du chiffre d’affaires généré par spectacle : plus le carré est rouge, plus le chiffre d'affaire généré est élevé.",
    title: "Top ventes de la semaine"
  },
  bBChmB: {
    title: "Top ventes du mois"
  },
  VFKuMyZ: {
    title: "Top ventes de l'année"
  },
  AbecP: {
    tooltip: "Représentation de votre public en fonction de leur genre.",
    title: "Genre",
    fillingRateQlikId: "DVPCXZG"
  },
  KXLqq: {
    tooltip:
      "Le nombre de contacts en base, autrement dit le nombre d’adresse mail récoltées à partir de votre base de données. ",
    insight:
      "Ce chiffre est clé pour votre activité, il reflète votre actif data qui a tout à gagner à grandir tous les jours.\nIl est ainsi toujours disponible en haut de l’écran et automatiquement mis à jour en fonction des filtres appliqués.",
    title: "Nombre de contacts"
  },
  JJjfTw: {
    tooltip:
      "Nombre de billets scannés au cours des 7 derniers jours.\nLa comparaison se fait avec les 7 mêmes jours de l'année passée.",
    title: "Billets scannés cette semaine"
  },
  VBrdPuf: {
    tooltip:
      "Nombre de billets scannés au cours des 30 derniers jours.\nLa comparaison se fait avec les 30 mêmes jours de l'année passée.",
    title: "Billets scannés ce mois-ci"
  },
  cjJQBj: {
    tooltip:
      "Nombre de billets scannés depuis le début de l'année calendaire en cours.\nLa comparaison est faite avec la même période un an plus tôt.",
    title: `Billets scannés en ${new Date().getFullYear()}`
  },
  Xru: {
    tooltip:
      "Nombre de scans au cours des 7 derniers jours.\nLa comparaison se fait avec les 7 mêmes jours de l'année passée.",
    title: "Scans cette semaine"
  },
  XqQmxf: {
    tooltip:
      "Nombre de scans au cours des 30 derniers jours.\nLa comparaison se fait avec les 30 mêmes jours de l'année passée.",
    title: "Scans ce mois-ci"
  },
  ZkEUNKX: {
    tooltip:
      "Nombre de scans depuis le début de l'année calendaire en cours.\nLa comparaison est faite avec la même période un an plus tôt.",
    title: `Scans en ${new Date().getFullYear()}`
  },
  RFmpkk: {
    tooltip: "Représentation de votre public en fonction de leur âge. ",
    aggregKpi: {
      qlikId: "PGSHnr",
      title: "Âge moyen des acheteurs"
    },
    fillingRateQlikId: "bNDqSK",
    title: "Age"
  },
  juHeq: {
    tooltip:
      "Représentation de votre public en fonction de leur ville de provenance.",
    title: "Ville des spectateurs",
    fillingRateQlikId: "gknEH",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  NpsfZT: {
    tooltip:
      "Représentation géographique des acheteurs de votre pays.\nPlus une zone est foncée, plus le nombre d'acheteurs est important.\nVous pouvez zoomer pour voir plus de détails.",
    title: "Acheteurs nationaux",
    exports: null
  },
  zRtQ: {
    tooltip:
      "Représentation géographique des acheteurs.\nPlus une zone est foncée, plus le nombre d'acheteurs est important.\nVous pouvez zoomer pour voir plus de détails sur une sélection.",
    title: "Acheteurs internationaux",
    exports: null
  },
  UnyBRU: {
    tooltip:
      "Représentation de la distance moyenne parcourue par votre public.\nElle est calculée à partir du code postal de leur ville et de celle des spectacles.",
    insight:
      "Cette fonctionnalité peut vous aider à évaluer le rayonnement de vos spectacles, autrement dit à savoir si vos spectacles attirent des publics éloignés géographiquement.\n",
    aggregKpi: {
      qlikId: "LzMPQj",
      title: "Distance moyenne parcourue par vos acheteurs"
    },
    title: "Distance parcourue",
    fillingRateQlikId: "gknEH"
  },
  QRNejcq: {
    title: "Nombre de billets vendus"
  },
  WKzfsG: {
    tooltip:
      "Représentation des commandes en fonction du nombre de billets par commande.",
    aggregKpi: {
      qlikId: "srepQ",
      title: "Nombre moyen de billets par commande"
    },
    title: "Nombre de billets par commande"
  },
  JAsu: {
    tooltip:
      "Représentation des acheteurs en fonction de leur nombre de commandes",
    aggregKpi: {
      qlikId: "FFTn",
      title: "Nombre moyen de commandes par acheteur"
    },
    title: "Récurrence d'achat"
  },
  HsfHUs: {
    tooltip:
      "Représentation des billets vendus en fonction du canal par lequel le spectateur a acheté son billet.",
    title: "Canaux de vente"
  },
  JtzfrC: {
    tooltip:
      "Représentation des billets en fonction du prix auquel ils ont été vendus.",
    aggregKpi: {
      qlikId: "bDFyNC",
      title: "Prix moyen d'un billet"
    },
    title: "Nombre de billets par tranche de prix"
  },
  DBBfTJg: {
    tooltip:
      "Représentation des billets vendus en fonction des catégories auxquelles ils appartiennent.",
    insight: "Repérez les spectateurs “Premium”.",
    title: "Catégorie de billets"
  },
  zhfhXS: {
    title: "Tarifs des billets",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  VYfjPvy: {
    tooltip:
      "Représentation de votre public trié en fonction de la date de leur dernière commande.",
    insight:
      "Distinguez les spectateurs étant venus récemment et ceux à reconquérir.",
    title: "Récence"
  },
  mvFYj: {
    tooltip:
      "Représentation votre public trié en fonction de la date de leur première commande.",
    insight:
      "Distinguez les spectateurs vous connaissant depuis longtemps et les plus récents.",
    title: "Ancienneté"
  },
  SCZjW: {
    tooltip:
      "Votre public trié en fonction de la date de l'anticipation avec laquelle ils achètent leurs billets. \nL'anticipation est la différence en nombre de jours entre la date de commande et la date du spectacle.",
    insight:
      "Connaître l'anticipation permet notamment de distinguer les acheteurs de dernières minutes.",
    aggregKpi: {
      qlikId: "BtzQuTw",
      title: "Anticipation moyenne"
    },
    title: "Anticipation"
  },
  "EqqGhg\ndvHUum\nPqKyrwp\n": {
    tooltip:
      "Représentation du nombre de billets commandés par année, par mois et par jour de la semaine au cours des dernières années.",
    insight:
      "Identifiez facilement les périodes les plus attractives pour la vente et l’envoi de vos newsletters et offres promotionnelles.",
    title: "Saisonnalité des commandes"
  },
  PFeW: {
    title: "Nombre de spectacles"
  },
  unPSFm: {
    title: "Nombre de séances"
  },
  gBXd: {
    tooltip: "Représentation des spectacles en fonction de leur genre.",
    title: "Rubriques des spectacles",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  NCPxZ: {
    tooltip:
      "Les parties prenantes d'un spectacle sont les personnes ayant participées à la création/production d'une représentation, que ce soit les acteurs ou le metteur en scène.\nCette liste est très dépendante de nos sources de données et est souvent loin d’être exhaustive.\n",
    title: "Parties prenantes",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  DZRpytH: {
    tooltip: "Représentation des spectacles en fonction du volume de billets.",
    title: "Taille des spectacles"
  },
  vjxsFL: {
    tooltip:
      "Représentation des spectacles en fonction du prix moyen du billet.",
    aggregKpi: {
      qlikId: "GygwKp",
      title: "Prix moyen d'achat pour un spectacle"
    },
    title: "Prix d'achat des spectacles"
  },
  mGJLkmg: {
    tooltip:
      "Représentation des spectacles en fonction du nombre moyen de billets par commande.",
    insight:
      "Distinguez les spectacles qui attirent des groupes et ceux pour lesquels les spectateurs viennent seuls",
    aggregKpi: {
      qlikId: "AymgyjV",
      title: "Nombre moyen de commandes pour un spectacle"
    },
    title: "Taille des commandes des spectacles"
  },
  JjxFu: {
    tooltip:
      "Pour chaque spectacle, on compte le nombre de spectateurs récurrents, c'est-à-dire des acheteurs qui ont commandé pour différentes séances.",
    aggregKpi: {
      qlikId: "KgUmSc",
      title: "Pourcentage moyen du public qui revient pour un spectacle"
    },
    title: "Les spectacles pour lesquels le public revient"
  },
  "vPrTgee\nCGYFdKT\njMAUSPm": {
    title: "Saisonnalité des spectacles"
  },
  jFeprY: {
    tooltip: "Les spectacles en fonction de l'anticipation moyenne.",
    insight:
      "Identifiez les spectacles pour lesquels votre public prépare sa venue !",
    aggregKpi: {
      qlikId: "JSEjG",
      title: "Anticipation moyenne pour un spectacle"
    },
    title: "Anticipation pour les spectacles"
  },
  yrRtre: {
    tooltip: "Durée pendant laquelle vos spectacles sont joués.",
    aggregKpi: {
      qlikId: "VhGZjp",
      title: "Période d'exploitation moyenne d'un spectacle"
    },
    title: "Période d'exploitation des spectacles"
  },
  vYvqBLC: {
    title: "Billets reservés en attente de paiement"
  },
  YnemCaE: {
    title: "Somme en attente de paiement"
  },
  TSSKph: {
    title: "Paiements annulés"
  },
  QtyJWDu: {
    title: "Renonciation spectacles futurs"
  },
  HycW: {
    title: "Somme impliquée par opération billetterie"
  },
  TXLafC: {
    title: "Liste des spectacles",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  mmJCq: {
    tooltip: "Vos spectacles liés à leurs informations de billetterie.",
    title: "Liste des spectacles",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  "3b38ef81-d831-442c-abef-926fc19f63d9": {
    tooltip: "Vos séances liées à leurs informations de billetterie.",
    title: "Liste des séances",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  NAPNDPh: {
    tooltip: "Tableau dont les lignes/colonnes dépendent de votre sélection",
    title: "Tableau dynamique",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  DQxGfq: {
    tooltip: "Sélectionne les colonnes du tableau dynamique",
    title: "Sélection des colonnes",
    exports: null
  },
  gQBWfc: {
    tooltip: "Heures durant lesquelles vos spectateurs achètent",
    title: "Heures d'achat"
  },
  jkfgBHP: {
    title: "Nombre de professionnels"
  },
  HKNpCmG: {
    title: "Type de professionnel"
  },
  FJDhyb: {
    title: "Activités des professionnels"
  },
  DCKCjQ: {
    title: "Volume de billets par professionel"
  },
  cmyste: {
    title: "Liste des spectacles",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  TjmQKy: {
    title: "Liste de professionels",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  KTaFf: {
    title: "Nombre d'acheteurs"
  },
  YXxdPC: {
    title: "Nombre de commandes"
  },
  xEJqgQR: {
    title: "Chiffre d'affaires"
  },
  hUek: {
    title: "Nombre de spectacles"
  },
  JwQMgaU: {
    title: "Nombre de séances"
  },
  WgPbz: {
    title: "Base de données source"
  },
  GTJmqvV: {
    title: "Nombre d'acheteurs"
  },
  zNHPhS: {
    title: "Nombre de commandes"
  },
  sRERm: {
    title: "Nombre de billets vendus"
  },
  SWUNPR: {
    title: "Nombre de spectacles"
  },
  JJyEp: {
    title: "Nombre d'acheteurs"
  },
  vhbaTq: {
    title: "Nombre de commandes"
  },
  agrPGg: {
    title: "Nombre de produits vendus"
  },
  UPmmdp: {
    title: "Nombre de produits"
  },
  LrYQyf: {
    tooltip: "La taille des carrés correspond au nombre de billets achetés.",
    title: "Types de billets achetés"
  },
  KJtRjA: {
    tooltip: "La taille des carrés correspond au nombre de produits achetés.",
    title: "Types de produits consommés"
  },
  gkAgvQ: {
    title: "Recettes billetterie"
  },
  EJmuM: {
    title: "Recettes cashless"
  },
  mUJqfM: {
    title: "Anticipation moyenne"
  },
  YrLNaF: {
    title: "Nombre moyen de billets par commande"
  },
  bfkUjJ: {
    title: "Prix moyen par commande"
  },
  yFvtzc: {
    title: "Nombre d'acheteurs"
  },
  wWkmj: {
    title: "Nombre de commandes"
  },
  eDUpEq: {
    title: "Nombre de billets vendus"
  },
  chfbWtp: {
    title: "Nombre de spectacles"
  },
  FmcuEWp: {
    title: "Nombre de séances"
  },
  VshAYR: {
    title: "Recettes billetterie"
  },
  RRDcG: {
    title: "Volume de ventes cashless hors TVA"
  },
  rgvvG: {
    title: "Nombre de commandes cashless"
  },
  sNPmMv: {
    title: "Nombre de produits commandés"
  },
  Pc: {
    title: "Nombre d'acheteurs cashless"
  },
  dvZpn: {
    title: "Prix moyen de la commande hors TVA"
  },
  QDcJPEN: {
    title: "Nombre moyen de produit par commande"
  },
  HHVW: {
    tooltip:
      "Vos points de ventes sont regroupés en zones, que vous pouvez analyser sur cette carte.",
    title: "Volume de vente par zone",
    exports: {
      jpg: defaultQlikMetadata.exports.jpg,
      pdf: defaultQlikMetadata.exports.pdf
    }
  },
  uFACkvK: {
    tooltip:
      "Vos différents points de vente géolocalisés.\nLa taille des cercles correspond au nombre d'acheteurs cashless.",
    title: "Volume de vente par point",
    exports: {
      jpg: defaultQlikMetadata.exports.jpg,
      pdf: defaultQlikMetadata.exports.pdf
    }
  },
  uRjP: {
    title: "Nombres de ventes par devise"
  },
  dfGmprT: {
    tooltip: "Les achats cashless, heure par heure.",
    title: "Temporalité des achats"
  },
  kNvTJL: {
    title: "Catégories de produits",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  jAgNaaS: {
    title: "Points de vente",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  AuCYd: {
    tooltip:
      "Le nombre de ventes qui se sont déroulées à proximité des scènes.",
    title: "Scènes liées aux ventes"
  },
  EPFKFSS: {
    title: "Jours de vente"
  },
  gfKuEj: {
    tooltip:
      "Chaque évènement de la programmation est lié aux :\n - ventes à proximité avant la représentation (30 min),\n - ventes pendant la représentation,\n - ventes après la représentation (15min),\n - les contrôles d’accès précédant la représentation, le même jour.",
    title: "Programmation et ventes liées",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  QGgqtkV: {
    title: "Achats dans le temps"
  },
  nBHGu: {
    title: "Consommation dans le temps"
  },
  pyTYQE: {
    title: "Contacts newsletters"
  },
  QvJCbYt: {
    title: "... ont eu une autre activité (billetterie, cashless...)"
  },
  xLaJCj: {
    title: "Centres d'intérêt",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  jZufG: {
    title: "Billets scannés"
  },
  nDMr: {
    title: "Billets non scannés"
  },
  TRDYKK: {
    title: "Scans"
  },
  RPPRva: {
    title: "No show"
  },
  sSjT: {
    title: "Billets avec info d'accès"
  },
  jfmcFL: {
    title: "Accès le plus fréquenté"
  },
  qPYFH: {
    title: "Créneau le plus fréquenté"
  },
  CMaPwjr: {
    title: "Liste des accès"
  },
  CNTphj: {
    title: "Répartition des scans par accès"
  },
  vJxPk: {
    title: "Billets scannés par heure"
  },
  DjmSA: {
    title: "Scans par heure"
  },
  YncvPf: {
    title: "Vue par date de séance"
  },
  jzjPgv: {
    title: "Liste par date de séance"
  },
  JznUGVf: {
    title: "Vue par date de scan"
  },
  WHnM: {
    title: "Liste par date de scan"
  },
  PjrtNEF: {
    title: "Vue par catégorie"
  },
  SVtFUxa: {
    title: "Vue par tarif"
  },
  dJCabh: {
    title: "Liste des formules d'abonnement"
  },
  BcBzPFJ: {
    title: "Absorption des scans"
  },
  Cfg: {
    title: "Absorption des scans répartis par accès"
  },
  uSfqyc: {
    title: "Absorption des billets scannés"
  },
  Khej: {
    title: "Absorption des billets scannés répartis par accès"
  },
  qxwkR: {
    title: "Représentation des scans par accès"
  },
  MPHHSj: {
    title: "Représentation des scans par jour"
  },
  ApPfV: {
    title: "Représentation des billets scannés par accès"
  },
  pFprDpR: {
    title: "Représentation des billets scannés par jour"
  },
  BtjtkF: {
    title: "Source de données"
  },
  WRpjzv: {
    title: "Nombre de produits"
  },
  BtzQuTw: {
    title: "Anticipation moyenne"
  },
  srepQ: {
    title: "Nombre moyen de billets par commande"
  },
  bDFyNC: {
    title: "Prix moyen d'un billet"
  },
  mxpjAEt: {
    title: "Listes newsletter",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  pAkDs: {
    tooltip:
      "La liste exhaustive de tous vos contacts avec les informations billetterie les concernant.",
    title: "Liste de contacts",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  fDEyG: {
    title: "Catégories des billets",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  enMdsK: {
    title: "Jours et éditions"
  },
  ADLtz: {
    title: "Chiffre d'affaires par jour"
  },
  YNjhYt: {
    title: "Nombres de billets par jour"
  },
  KDrfTTM: {
    insight:
      "Vous pouvez comparer les éditions, les jours et leur croisement !\nN'hésitez pas à changer la mesure sur la gauche des graphiques.",
    aggregKpi: {
      qlikId: "PGSHnr",
      title: "Âge moyen des acheteurs"
    },
    title: "Éditions du festival"
  },
  VxhhyNe: {
    aggregKpi: {
      qlikId: "BtzQuTw",
      title: "Anticipation moyenne"
    },
    title: "Anticipation moyenne par année"
  },
  ewwJpBB: {
    aggregKpi: {
      qlikId: "BtzQuTw",
      title: "Anticipation moyenne"
    },
    title: "Jours du festival"
  },
  sfmJMb: {
    aggregKpi: {
      qlikId: "bfkUjJ",
      title: "Prix moyen par commande"
    },
    title: "Panier moyen"
  },
  JQtNK: {
    tooltip:
      "Nombre d'années différentes où le même contact est présent dans votre base.",
    insight:
      "Repérez vos acheteurs fidèles, qui viennent régulièrement à vos évènements !",
    title: "Récurrence du public"
  },
  yEyKqPy: {
    insight:
      "Vous pouvez sélectionner les contacts qui sont revenus d'une année sur l'autre !",
    title: "Rétention contre Captation"
  },
  cnAfAq: {
    tooltip:
      "Répartition des contacts suivant leur activité durant ces trois dernières années.",
    title: "Intersection d'années",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  xGBfV: {
    tooltip:
      "Analyse, pour chaque année, du nombre de contacts qui étaient présents l'année précédente (rétention) ou absents (captation).",
    title: "Retour des spectateurs de l'année précédente",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  WznPpe: {
    title: "Formules d'abonnement",
    exports: {
      csv: defaultQlikMetadata.exports.csv,
      xlsx: defaultQlikMetadata.exports.xlsx
    }
  },
  LqNvYDR: {
    title: "Billets cette semaine"
  },
  jtwHHT: {
    title: "Commandes cette semaine"
  },
  Pmsqt: {
    title: "Séances cette semaine"
  },
  mytsmpX: {
    title: "Spectacles cette semaine"
  },
  XBdPh: {
    title: "Chiffre d'affaires cette semaine"
  },
  MJMAKF: {
    title: "Commandes ce mois"
  },
  XqhPAYW: {
    title: "Séances ce mois"
  },
  vZPCxX: {
    title: "Spectacles ce mois"
  },
  tRjnJz: {
    title: "Chiffre d'affaires ce mois"
  },
  ErUmgms: {
    title: "Commandes cette année"
  },
  FcPEpj: {
    title: "Séances cette année"
  },
  XxBzyqY: {
    title: "Spectacles cette année"
  },
  LWjnqS: {
    title: "Chiffre d'affaires cette année"
  },
  ZpYrx: {
    title: "Contrôle d'accès par salle"
  },
  jnXEvN: {
    title: "Jour du contrôle d'accès"
  },
  NceT: {
    title: "Localisation du contrôle",
    exports: {
      jpg: defaultQlikMetadata.exports.jpg,
      pdf: defaultQlikMetadata.exports.pdf
    }
  },
  UGYgSph: {
    title: "Nombre de spectateurs contrôlés"
  },
  NHUrmP: {
    title: "Nombre de contrôle d'accès"
  },
  pkbRr: {
    title: "Année de contrôle d'accès"
  },
  zEWGp: {
    tooltip:
      "Comparaison des ventes sélectionnées par rapport au jour de la représentation (à J-1, J-2, J-3 etc.).",
    title: "Ventes de billets par anticipation"
  },
  BYpp: {
    tooltip:
      "Comparaison des ventes cumulées sélectionnées par rapport au jour de la représentation (à J-1, J-2, J-3 etc.).",
    title: "Ventes cumulées de billets par anticipation"
  },
  STrqqk: {
    tooltip: "Répartition des opt-ins source dans les catégories d'opt-ins.",
    title: "Résultat de la configuration"
  },
  FLrmhD: {
    tooltip:
      "Liste des données additionnelles, dans lesquels vous retrouverez les opt-ins de vos source à l'état brut.",
    title: "Données additionnelles spectateurs"
  },
  MryLz: {
    title: "Contacts opt-ins (en %)"
  },
  PUcRWUM: {
    tooltip:
      "Vos différentes catégories d'opt-in ainsi que le nombre de contacts associés.",
    title: "Catégories d'opt-ins"
  },
  eNunpf: {
    tooltip:
      "Ce graphique affiche selon vos sources le nombre de contacts avec consentement ou non.",
    title: "Consentement selon vos bases sources"
  },
  bmgSv: {
    tooltip:
      "Représentation de vos ventes suivant le critère sélectionné.\nLa taille des bulles correspond au prix moyen du billet.",
    title: "Graphe dynamique"
  }
};

export default { getQlikMetadata, defaultQlikMetadata, qlikMetadata };
