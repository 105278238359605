export default {
  exportTypes: ["contacts", "shows"],
  pages: {
    keyMetrics: {
      component: "producer/ProducerHomepage",
      label: "Indicateurs clés",
      icon: "home",
      order: 110
    },
    publicProfile: {
      component: "producer/ProducerPublicProfile",
      label: "Portrait",
      category: "public",
      order: 210
    },
    publicConsumption: {
      component: "producer/ProducerPublicConsumption",
      label: "Consommation",
      category: "public",
      order: 220
    },
    publicOptin: {
      component: "producer/ProducerPublicOptin",
      label: "Consentement",
      category: "public",
      order: 225
    },
    publicTemporality: {
      component: "producer/ProducerPublicTemporality",
      label: "Temporalité",
      category: "public",
      order: 230
    },
    publicNewsletters: {
      component: "default/DefaultPublicNewsletters",
      label: "Newsletters",
      category: "public",
      order: 240
    },
    publicList: {
      component: "producer/ProducerPublicList",
      label: "Liste",
      category: "public",
      order: 250
    },
    showsSales: {
      component: "producer/ProducerShowsSales",
      label: "Volumes de vente",
      category: "shows",
      order: 310
    },
    showsTemporality: {
      component: "producer/ProducerShowsTemporality",
      label: "Temporalité",
      category: "shows",
      order: 320
    },
    showsList: {
      component: "producer/ProducerShowsList",
      label: "Liste",
      category: "shows",
      order: 330
    },
    showsComparison: {
      component: "producer/ProducerShowsComparison",
      label: "Comparaison",
      category: "shows",
      order: 340
    },
    expertTemporality: {
      component: "producer/ProducerExpertTemporality",
      label: "Temporalité",
      category: "expert",
      order: 410
    },
    expertDynamicTable: {
      component: "producer/ProducerExpertDynamicTable",
      label: "Tableau dynamique",
      category: "expert",
      order: 420
    },
    expertSegmentManagement: {
      component: "producer/ProducerExpertSegmentManagement",
      label: "Combinaison",
      category: "expert",
      order: 430
    }
    // expertRecurrence: null,  => why??
    // publicOptin: null        => why??
  }
};
