<template>
  <div>
    <el-dropdown trigger="click">
      <span class="el-dropdown-link o-sidebar-profile">
        <fa :icon="['fal', 'user']" size="lg" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="logout"
          >Se déconnecter</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <br />
    <b>{{ user && user.name }}</b>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers("auth");

export default {
  name: "SideBarProfile",
  computed: mapState(["user"]),
  methods: mapActions(["logout"])
};
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
}
</style>
