<template>
  <el-dialog
    :visible.sync="open"
    :append-to-body="true"
    v-bind="{ title: 'Confirmation', ...$attrs }"
  >
    <slot><div v-html="message"></div></slot>
    <template #footer>
      <slot name="footer">
        <slot name="cancelButton">
          <el-button v-bind="cancelBtnAttrs" @click="$emit('cancel')">{{
            cancelBtnText
          }}</el-button>
        </slot>
        <slot name="confirmButton">
          <el-button v-bind="confirmBtnAttrs" @click="$emit('confirm')">{{
            confirmBtnText
          }}</el-button>
        </slot>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    confirmBtnText: { type: String, default: "Confirmer" },
    confirmBtnAttrs: { type: Object, default: () => ({ type: "primary" }) },
    cancelBtnText: { type: String, default: "Annuler" },
    cancelBtnAttrs: { type: Object, default: () => ({}) },
    message: { type: String, default: "Etes-vous certain?" },
    open: { type: Boolean, required: true }
  }
};
</script>

<style scoped></style>
