<template>
  <el-form class="py-4" label-position="left" label-width="33%">
    <el-form-item label="Trier par">
      <el-select v-model="by" class="w-full">
        <el-option label="Date" value="date"></el-option>
        <el-option label="Auteur" value="author"></el-option>
        <el-option label="Nom" value="title"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="En ordre">
      <el-select v-model="reverseOrder" class="w-full">
        <el-option label="Décroissant" :value="false"></el-option>
        <el-option label="Croissant" :value="true"></el-option>
      </el-select>
    </el-form-item>
    <div class="text-center">
      <el-link icon="el-icon-refresh-left" @click="reset">
        Réinitialiser le tri
      </el-link>
    </div>
  </el-form>
</template>

<script>
export default {
  props: { value: { type: Object, required: true } },
  computed: ["by", "reverseOrder"].reduce(
    (acc, field) => ({
      ...acc,
      [field]: {
        get: ({ value }) => value[field],
        set(value) {
          this.$emit("input", { ...this.value, [field]: value });
        }
      }
    }),
    {}
  ),
  methods: {
    reset() {
      this.$emit("input", { by: "date", reverseOrder: false });
    }
  }
};
</script>

<style scoped></style>
