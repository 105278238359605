<template>
  <el-card shadow="never" class="m-8">
    <div slot="header">Choisissez une application</div>
    <el-input
      autofocus="true"
      placeholder="Rechercher"
      v-model="search"
      class="pb-4"
    ></el-input>
    <div
      v-for="{ qDocId, qDocName } in filteredAppList"
      :key="qDocId"
      class="p-4 text-gray-800 cursor-pointer rounded-lg hover:bg-blue-50"
      @click="openApp(qDocId)"
    >
      {{ qDocName }}
    </div>
  </el-card>
</template>

<script>
import { escapeRegExp } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers("qlik");

export default {
  created() {
    // Automatically open the only app of a user if he try to come here manually
    if (this.appList.length === 1) this.openApp(this.appList[0].qDocId);
  },
  data: () => ({ search: "" }),
  computed: {
    ...mapState(["appList"]),
    filteredAppList() {
      const { appList, search } = this;

      return appList.filter(({ qDocName }) =>
        RegExp(escapeRegExp(search), "i").test(qDocName)
      );
    }
  },
  methods: mapActions(["openApp"])
};
</script>

<style scoped></style>
