<template>
  <div id="app">
    <Loader :loading="!appList.length">
      <div class="h-screen flex">
        <SideBar />
        <div class="flex-grow flex flex-col overflow-hidden">
          <Header />
          <div
            id="content"
            class="flex-grow overflow-auto scrolling-touch"
            style="background-color: rgb(237, 242, 247)"
          >
            <router-view />
          </div>
          <Footer v-if="appLoaded" />
        </div>
      </div>
      <BookmarksDrawer v-if="appLoaded" />
    </Loader>
  </div>
</template>

<script>
import SideBar from "@/layout/SideBar";
import BookmarksDrawer from "@/layout/BookmarksDrawer/BookmarksDrawer.vue";
import Header from "@/layout/Header";
import Footer from "@/layout/Footer";
import operator from "@/config/operator.js";
import Loader from "@/layout/Loader.vue";
import gql from "graphql-tag";
import { setGTagUser } from "@/plugins/gTag.js";
import { setMixpanelUser } from "@/plugins/mixpanel.js";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapAuthState,
  mapGetters: mapAuthGetters,
  mapActions: mapAuthActions
} = createNamespacedHelpers("auth");
const {
  mapState: mapQlikState,
  mapGetters: mapQlikGetters,
  mapActions: mapQlikActions
} = createNamespacedHelpers("qlik");

export default {
  name: "app",
  components: { Loader, SideBar, BookmarksDrawer, Header, Footer },
  created() {
    this.isAuthenticated || this.handleAuthentication();
  },
  beforeMount() {
    // Apply operator name and favicon
    const { title, favicon } = operator;
    const link = document.createElement("link");

    Object.assign(link, {
      rel: "shortcut icon",
      href: `${favicon}?v=${Date.now()}` // append timestamp to force refresh
    });
    document.head.appendChild(link);
    document.title = title;
  },
  mounted() {
    this.isAuthenticated && !this.appList.length && this.init();
  },
  data() {
    return { me: null };
  },
  apollo: {
    me: {
      query: gql`
        {
          userMe {
            id
            name
            email
            tenantName
          }
        }
      `,
      update({ userMe }) {
        if (userMe) {
          setGTagUser(userMe);
          setMixpanelUser(userMe);
          this.intercom(userMe);
        }
        return userMe;
      },
      skip() {
        return !this.isAuthenticated;
      }
    }
  },
  computed: {
    ...mapAuthState(["user"]),
    ...mapAuthGetters(["isAuthenticated"]),
    ...mapQlikState(["appList"]),
    ...mapQlikGetters(["appLoaded"])
  },
  methods: {
    ...mapAuthActions(["handleAuthentication"]),
    ...mapQlikActions(["init"]),
    intercom({ id, name, email }) {
      const { intercom } = operator;
      if (!intercom || !this.$intercom) return;

      this.$intercom.boot({ user_id: id, name, email });
    }
  }
};
</script>

<style></style>
