<template>
  <div>
    <div v-if="loading" class="flex flex-col items-center justify-center">
      <img :src="loaderTheme.logo" class="loading-logo" />
      <div id="page-spinner">
        <div
          class="double-bounce1"
          :style="`background-color: ${loaderTheme.color}`"
        ></div>
        <div
          class="double-bounce2"
          :style="`background-color: ${loaderTheme.color}`"
        ></div>
      </div>
      <div class="loading-text poppins">
        Chargement de vos données en cours...
      </div>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import operator from "@/config/operator.js";

export default {
  props: { loading: { type: Boolean, required: true } },
  computed: { loaderTheme: () => operator.theme.loader }
};
</script>

<style scoped>
#page-spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 50px auto;
}

.loading-logo {
  max-height: 120px;
  margin: auto;
  margin-top: 150px;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loading-text {
  color: #8c8c8c;
  font-size: 18px;
  text-align: center;
  font-style: italic;
}
</style>
