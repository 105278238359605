<template>
  <transition name="fade">
    <div
      v-show="visible"
      class="drawer-mask fixed inset-0 h-screen w-screen z-30"
      @click.self="$emit('update:visible', false)"
    >
      <transition name="right-to-left">
        <div
          v-show="visible"
          class="drawer-container absolute inset-y-0 right-0 bg-white"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default { props: { visible: { type: Boolean, required: true } } };
</script>

<style scoped>
.drawer-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.drawer-container {
  width: 30%;
  min-width: 455px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.right-to-left-enter-active,
.right-to-left-leave-active {
  transition: right 0.2s;
}
.right-to-left-enter,
.right-to-left-leave-to {
  right: -30%;
}
</style>
