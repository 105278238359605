<template>
  <div id="CurrentSelections" class="qvobjects"></div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  computed: mapState(["app"]),
  mounted() {
    this.app.getObject("CurrentSelections", "CurrentSelections");
  }
};
</script>

<style lang="scss">
#CurrentSelections {
  width: 100%;

  .qv-panel-current-selections {
    background-image: none;
    background: none;
    font-family: "Open Sans", sans-serif;
  }

  .qv-panel-current-selections .wrap {
    border-bottom: none;
  }
  .qv-panel-current-selections .buttons {
    display: none;
  }

  .qv-panel-current-selections ul {
    border-spacing: 3px 0;
  }

  .qv-panel-current-selections li {
    border-right: none;
  }

  .qv-panel-current-selections .buttons-end {
    display: none;
  }

  .qv-panel-current-selections .item {
    font-size: 12px;
    border: 0.1rem solid #dcdfe6;
    border-radius: 0.3rem;
    color: #858ea3;
    background: white;
    width: 150px;
  }

  .touch-off .qv-panel-current-selections .item:hover {
    background: white;
  }

  .qv-panel-current-selections .values {
    /*display: none;*/
  }

  .qv-panel-current-selections .item .qv-state-count-bar {
    display: none;
  }

  .qv-panel-current-selections .remove {
    border-left: 0.1rem solid #dcdfe6;
    color: #858ea3;
  }

  .touch-off .qv-panel-current-selections .remove:hover {
    color: #858ea3;
  }

  .lui-popover {
    box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.1);
    border: 0.1rem solid #dcdfe6;
    border-radius: 0.3rem;
  }

  .lui-popover .lui-popover__arrow--bottom:before {
    border-top-color: #dcdfe6;
  }

  .qv-listbox li.serverAlternative {
    background: #e0e0e0;
  }

  .touch-off .sel-toolbar-btn:hover:not(:active):not(:disabled) {
    border: none;
  }
}
</style>
