const euroFormat = { qNumFormat: { qType: "M", qFmt: "# ##0 €;-# ##0 €" } };
const formats = {
  xlsx: { label: "fichier Excel", qlikFormat: "OOXML" },
  csv: { label: "fichier CSV", qlikFormat: "CSV_C" }
};

export default {
  shows: {
    label: "spectacles",
    fields: ["Nom du spectacle"],
    measures: [{ qLibraryId: "VDrpmAu", qDef: euroFormat }],
    formats
  },
  contacts: {
    label: "contacts",
    fields: ["Email du spectateur"],
    measures: [{ qLibraryId: "tmGcLaE", qDef: euroFormat }],
    formats
  },
  buyersIds: {
    label: "ID des acheteurs",
    fields: ["customer_provider_id"],
    measures: [{ qLibraryId: "tmGcLaE", qDef: euroFormat }],
    formats: {
      ...formats,
      merlin: { label: "fichier Merlin", qlikFormat: "CSV_C" }
    }
  },
  buyersWithEmails: {
    label: "acheteurs",
    fields: ["customer_provider_id", "Email du spectateur"],
    measures: [{ qLibraryId: "tmGcLaE", qDef: euroFormat }],
    formats: {
      ...formats,
      billetreduc: { label: "vers l'API billetreduc", qlikFormat: "CSV_C" }
    }
  },
  pros: {
    label: "professionnels",
    fields: [
      "Nom du professionnel",
      "Poste du professionnel",
      "Société du professionnel",
      "E-mail du professionnel",
      "Type de professionnel"
    ],
    measures: [
      { qLibraryId: "QpXeaHD" },
      { qLibraryId: "pcuEB" },
      { qLibraryId: "hgdGjJj" },
      { qLibraryId: "bNWJDrf" },
      { qLibraryId: "VjXMTu" },
      { qLibraryId: "pUpmwsg" }
    ],
    formats
  },
  activetrail: {
    overwrite: "contacts",
    label: "contacts",
    fields: ["Email du spectateur"],
    measures: [
      { qLibraryId: "tmGcLaE", qDef: euroFormat },
      { qLibraryId: "HVBmCzA" },
      { qLibraryId: "ejQvBUv" },
      { qLibraryId: "QDaKQyG" }],
    formats: {
      ...formats,
      activetrail: { label: "vers l'API ActiveTrail", qlikFormat: "CSV_C" }
    }
  }
};
