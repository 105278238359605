import auth0Config from "./auth0.js";

const ignition = {
  title: "Ignition by Delight",
  favicon: "/favicon_ignition.png",
  theme: {
    loader: {
      color: "#ffcb13",
      logo: require("@/assets/themes/ignition/loader.svg")
    },
    mainColor: null,
    spinnerColor: "#ff911f73",
    sidebarLogo: {
      src: require("@/assets/themes/ignition/sidebar_logo.png"),
      style: "height: 40px"
    },
    brandingPlaceholder: require("@/assets/themes/ignition/branding_placeholder.png"),
    redirectHome: true,
    title: "Ignition",
    background: "",
    displaySwitchButton: true,
    displayDocumentationButton: true
  },
  auth0Config: { ...auth0Config, clientID: "OD5uBqrgZBpLBcjzD5jiOUlCxSbnzA49" },
  intercom: true
};

const ed360 = {
  title: "ED360",
  favicon: "/favicon_ed360.png",
  theme: {
    loader: {
      logo: require("@/assets/themes/ed360/loader.svg"),
      color: "#D43C33"
    },
    mainColor: "#D43C34",
    spinnerColor: "#D16964",
    sidebarLogo: {
      src: require("@/assets/themes/ed360/sidebar_logo.png"),
      style: "height: 48px; position: relative; left: 15px;"
    },
    brandingPlaceholder: require("@/assets/themes/ed360/branding_placeholder.png"),
    redirectHome: false,
    title: null,
    background: "#F5F7FA",
    displaySwitchButton: false,
    displayDocumentationButton: false
  },
  auth0Config: { ...auth0Config, clientID: "ozqJN0CeYpU6VTgBHX2lhPHIen59DJbE" },
  intercom: false
};

const { hostname } = window.location;
export default hostname.includes("ed360") ? ed360 : ignition;
