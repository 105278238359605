import producer from "./producer.js";
const { exportTypes, pages } = producer;

export default {
  exportTypes,
  pages: {
    ...{ ...pages, showsTemporality: null },
    showsSales: {
      component: "sport/SportShowsSales",
      label: "Volumes de vente",
      category: "shows",
      order: 310
    }
  }
};
