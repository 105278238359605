<template>
  <div>
    <el-tooltip content="Téléchargement" placement="bottom">
      <a @click="open = true">
        <fa :icon="['fal', 'arrow-alt-to-bottom']" size="lg" fixed-width />
      </a>
    </el-tooltip>
    <el-dialog
      title="Export des données"
      :visible.sync="open"
      class="text-left cursor-auto"
      @closed="resetState"
    >
      <el-alert
        v-show="exportResult"
        v-bind="exportResult"
        show-icon
        :closable="false"
      >
        <div v-show="exportUrl">
          Si le télechargement ne se lance pas
          <a :href="exportUrl" class="text-blue-600 underline">cliquez ici</a>
        </div>
      </el-alert>

      <el-form
        v-show="!(exportUrl || exportResult)"
        @submit.native.prevent="submit"
        label-width="auto"
        :disabled="exporting"
      >
        <el-form-item label="Exporter la selection des">
          <el-radio
            v-for="({ label, formats }, exportType) in app.exportTypes"
            v-model="selectedExportType"
            :key="exportType"
            :label="exportType"
            @change="selectedExportFormat = Object.keys(formats)[0]"
          >
            {{ label }}
          </el-radio>
        </el-form-item>

        <el-form-item label="Méthode">
          <el-radio
            v-for="({ label }, exportFormat) in exportFormats"
            v-model="selectedExportFormat"
            :key="exportFormat"
            :label="exportFormat"
          >
            {{ label }}
          </el-radio>
        </el-form-item>

        <el-form-item
          v-show="['billetreduc', 'activetrail'].includes(selectedExportFormat)"
          label="Nom de la cible"
        >
          <el-input v-model="targetName" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          v-show="selectedExportFormat === 'activetrail'"
          label="Compte ActiveTrail"
        >
          <el-select
            v-model="accountName"
            :loading="$apollo.queries.activetrailAccounts.loading"
            loading-text="Chargement..."
            class="w-full"
          >
            <el-option
              v-for="activetrailAccount in activetrailAccounts"
              :key="activetrailAccount"
              :value="activetrailAccount"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          v-show="selectedExportFormat === 'billetreduc'"
          label="Description de la cible"
        >
          <el-input
            type="textarea"
            v-model="targetDescription"
            auto-complete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <div v-show="!(exportUrl || exportResult)">
          <el-button :disabled="exporting" @click="open = false">
            Annuler
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            @click="submit"
            :loading="exporting"
            :disabled="!canExport"
          >
            Valider
          </el-button>
        </div>
        <el-button
          v-show="exportUrl || exportResult"
          type="primary"
          @click="open = false"
        >
          Fermer
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qlikConfig from "@/config/qlik.js";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapQlikState } = createNamespacedHelpers("qlik");
const { mapState: mapAuthState } = createNamespacedHelpers("auth");
import gql from "graphql-tag";

export default {
  created() {
    // Because we can't use a value from VueX as initial data value
    const { exportTypes } = this.app;
    this.selectedExportType = Object.keys(exportTypes)[0];
    const { formats } = exportTypes[this.selectedExportType];
    this.selectedExportFormat = Object.keys(formats)[0];
  },
  data: () => ({
    open: false,
    selectedExportType: "",
    selectedExportFormat: "",
    accountName: "",
    activetrailAccounts: [],
    targetName: "",
    targetDescription: "",
    exportUrl: "",
    exporting: false,
    exportResult: null
  }),
  apollo: {
    activetrailAccounts: {
      query: gql`
        query {
          userMe {
            id
            activetrailAccounts
          }
        }
      `,
      update: ({ userMe: { activetrailAccounts } }) => activetrailAccounts,
      skip() {
        return this.selectedExportFormat !== "activetrail";
      }
    }
  },
  computed: {
    ...mapQlikState(["app"]),
    ...mapAuthState(["accessToken"]),
    exportFormats() {
      return this.app.exportTypes[this.selectedExportType].formats;
    },
    canExport: ({ selectedExportFormat, accountName, targetName }) =>
      !!(selectedExportFormat === "billetreduc"
        ? targetName
        : selectedExportFormat === "activetrail"
        ? accountName && targetName
        : true)
  },
  methods: {
    resetState() {
      const { exportTypes } = this.app;
      const selectedExportType = Object.keys(exportTypes)[0];
      const { formats } = exportTypes[this.selectedExportType];
      const selectedExportFormat = Object.keys(formats)[0];

      Object.assign(this.$data, {
        ...this.$options.data.apply(this),
        selectedExportType,
        selectedExportFormat
      });
    },
    submit() {
      Object.assign(this.$data, { exporting: true, exportResult: null });
      const { protocol, host } = qlikConfig;
      const {
        app,
        selectedExportType,
        selectedExportFormat,
        accountName,
        targetName,
        targetDescription
      } = this;
      const { fields, measures, formats } = app.exportTypes[selectedExportType];
      const { qlikFormat } = formats[selectedExportFormat];
      const qTable = app.createTable(fields, measures);
      const downloadExport = exportUrl => {
        Object.assign(this.$data, {
          exportUrl,
          exporting: false,
          exportResult: {
            type: "success",
            title: "L'export a été réalisé avec succès !"
          }
        });
        window.open(exportUrl);
      };

      qTable.OnData.bind(() => {
        qTable.exportData(
          { format: qlikFormat, download: false, state: "A" },
          url => {
            qTable.OnData.unbind();
            let exportUrl = `${protocol}${host}${url}`;

            // handle special exports
            const customExportTypes = ["merlin", "billetreduc", "activetrail"];
            if (customExportTypes.includes(selectedExportFormat)) {
              const { VUE_APP_API_URL: apiUrl } = process.env;

              axios
                .post(
                  `${apiUrl}/api/exports/to_api`,
                  {
                    api_name: selectedExportFormat,
                    ...(accountName && { account_name: accountName }),
                    target_name: targetName,
                    file_url: exportUrl,
                    ...(targetDescription && { description: targetDescription })
                  },
                  { headers: { Authorization: `Bearer ${this.accessToken}` } }
                )
                .then(({ data }) => {
                  const { data: { fileUrl } = {} } = data;
                  if (fileUrl) return downloadExport(fileUrl);
                  Object.assign(this.$data, {
                    exporting: false,
                    exportResult: {
                      type: "success",
                      title: "L'export vers l'API a été réalisé avec succès !"
                    }
                  });
                })
                .catch(({ response: { status } }) =>
                  Object.assign(this.$data, {
                    exporting: false,
                    exportResult: {
                      type: "error",
                      title:
                        status === 413
                          ? "Échec de l'export. L'API a répondu avec le statut 413 (Request Entity Too Large). Essayez avec un nombre d'acheteurs plus petit."
                          : "Une erreur inattendue est survenue."
                    }
                  })
                );
            } else {
              downloadExport(exportUrl);
            }
          }
        );
      });
    }
  }
};
</script>

<style scoped></style>
