<template>
  <div
    class="o-sidebar"
    :style="{ background: theme.mainColor }"
    :class="{ 'has-topMenu': hasTopMenu }"
  >
    <div class="o-sidebar-mobileButton">
      <fa :icon="['fal', 'angle-right']" size="2x" />
    </div>
    <div class="o-sidebar-topMenu">
      <div class="o-sidebar-header">
        <img
          class="o-sidebar-logo o-sidebar-full-logo"
          :src="require('@/assets/layout/img/full-logo.svg')"
          alt="Delight"
        />
      </div>
      <ul class="o-sidebar-topList">
        <li class="o-sidebar-topItem">
          <a class="o-sidebar-topLink o-sidebar-topLink--ignition" href="#">
            <img
              class="o-sidebar-logo"
              :src="require('@/assets/layout/img/logo-ignition.svg')"
              alt
            />
            <span class="o-sidebar-title">Ignition</span>
          </a>
        </li>
        <li class="o-sidebar-topItem">
          <a
            class="o-sidebar-topLink o-sidebar-topLink--activation"
            href="https://app.delight-data.com/activation"
          >
            <img
              class="o-sidebar-logo"
              :src="require('@/assets/layout/img/logo-activation.svg')"
              alt
            />
            <span class="o-sidebar-title">Activation</span>
          </a>
        </li>
        <li class="o-sidebar-topItem">
          <a
            class="o-sidebar-topLink o-sidebar-topLink--relation"
            href="https://app.delight-data.com/relation"
          >
            <img
              class="o-sidebar-logo"
              :src="require('@/assets/layout/img/logo-relation.svg')"
              alt
            />
            <span class="o-sidebar-title">Relation</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="o-sidebar-header" :style="{ background: theme.background }">
      <img
        @click="redirectHome"
        class="o-sidebar-logo"
        :style="theme.sidebarLogo.style"
        :src="theme.sidebarLogo.src"
        alt
      />
      <h1 v-if="theme.title" @click="redirectHome" class="o-sidebar-title">
        {{ theme.title }}
      </h1>
      <div
        v-if="theme.displaySwitchButton"
        class="o-sidebar-menuButton"
        @click.self="toogleMenu"
      ></div>
    </div>
    <div class="o-sidebar-content">
      <div class="o-sidebar-bubble">
        <img
          class="o-sidebar-bubbleImage user-logo"
          :src="brandingLogoSrc"
          alt
        />
      </div>
      <div class="text-center font-bold mb-4">
        {{ organizationName }}
      </div>
      <div class="flex-grow relative">
        <div class="absolute inset-0 overflow-y-auto">
          <nav class="o-sidebar-nav">
            <ul class="o-sidebar-navList">
              <SideBarNavItem
                v-for="{ name, ...rest } in routes"
                :key="name"
                v-bind="{ name, ...rest }"
              ></SideBarNavItem>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <router-link
      v-if="
        ['coupe_du_monde_de_rugby', 'delight_demo', 'delight_users'].includes(
          me.tenantName
        )
      "
      :to="{ name: 'configuration' }"
      class="px-2 py-4 -mb-4 text-center"
    >
      <span style="color: #1877f2">
        <fa :icon="['fab', 'facebook']" size="lg" />
      </span>
      Facebook leads
    </router-link>
    <div class="o-sidebar-footer">
      <SideBarProfile />
    </div>
  </div>
</template>

<script>
import SideBarNavItem from "@/layout/SideBarNavItem";
import SideBarProfile from "@/layout/SideBarProfile";
import operator from "@/config/operator.js";
import gql from "graphql-tag";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("qlik");

export default {
  name: "SideBar",
  components: { SideBarNavItem, SideBarProfile },
  data: () => ({ hasTopMenu: false, me: {} }),
  apollo: {
    me: {
      query: gql`
        {
          userMe {
            id
            tenantName
            tenantDisplayName
            brandings {
              logoUrl
              serviceName
            }
          }
        }
      `,
      update: ({ userMe }) => userMe
    }
  },
  computed: {
    ...mapState(["app"]),
    theme: () => operator.theme,
    organizationName() {
      const { tenantName, tenantDisplayName } = this.me;
      return tenantDisplayName || tenantName;
    },
    brandingLogoSrc() {
      const { brandingPlaceholder } = this.theme;
      const { brandings = [] } = this.me;
      const branding = brandings.find(
        ({ serviceName }) => serviceName === "organization"
      );

      return branding ? branding.logoUrl : brandingPlaceholder;
    },
    routes() {
      const { routes = [] } = this.app;

      return routes;
    }
  },
  methods: {
    toogleMenu() {
      this.hasTopMenu = !this.hasTopMenu;
    },
    redirectHome() {
      if (this.theme.redirectHome) {
        this.$router.push({ path: "/" }).catch(() => {});
      }
    }
  }
};
</script>

<style scoped>
.o-sidebar-logo {
  height: 40px;
  cursor: pointer;
}

.o-sidebar-full-logo {
  width: 110px;
}

.o-sidebar-title {
  cursor: pointer;
}
.o-sidebar-bubble {
  position: relative;
  flex-shrink: 0;
}

img.user-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.o-sidebar-bubble {
  padding: 0;
}

.o-sidebar-footer-logo {
  position: relative;
  bottom: 8px;
  left: 63px;
  display: block;
  height: 18px;
}
</style>
