<template>
  <div class="m-counter-item">
    <div class="m-counter-label">{{ title }}</div>
    <div class="m-counter-number odometer">{{ value }}</div>
    <div class="kpi-hidden" :id="qlikId + _uid"></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  name: "Counter",
  props: {
    qlikId: String,
    preserveOnDestroy: { type: Boolean, default: false },
    title: String
  },
  data: () => ({ objectModel: null, value: null }),
  computed: mapState(["app"]),
  mounted() {
    this.app
      .getObject(this.qlikId + this._uid, this.qlikId)
      .then(objectModel => {
        objectModel.Validated.bind(this.updateData);
        this.objectModel = objectModel;
        this.updateData();
      });
  },
  beforeDestroy() {
    if (this.objectModel && !this.preserveOnDestroy) {
      this.app
        .destroySessionObject(this.objectModel.layout.qInfo.qId)
        .then(() => this.objectModel.close())
        .then(() => (this.objectModel = null));
    }
  },
  methods: {
    updateData() {
      this.value = this.objectModel.layout.qHyperCube.qDataPages[0].qMatrix[0][0].qText;
    }
  }
};
</script>

<style>
.m-counter-item {
  min-width: 108.37px;
}

.kpi-hidden {
  display: none;
}
</style>
