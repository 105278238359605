import axios from "axios";
import couchdb from "@/plugins/couchdb.js";
import qlikConfig from "@/config/qlik.js";
import { getConsolidatedAppConfig } from "@/config/templates/helpers.js";
import router from "@/router.js";

const { VUE_APP_API_URL: apiUrl } = process.env;
const { host, protocol, prefix } = qlikConfig;

const state = () => ({ qlik: {}, app: {}, appList: [] });

const mutations = {
  setQlik: (state, qlik) => (state.qlik = qlik),
  setAppList: (state, appList) => (state.appList = appList),
  setApp: (state, app) => {
    localStorage.setItem("appId", app.qDocId);
    state.app = app;
  },
  resetState: currentState => Object.assign(currentState, state())
};

const getters = {
  appLoaded: ({ app }) => !!app.qDocName,
  hasTag: ({ app: { tags = [] } }) => tag => tags.includes(tag)
};

const actions = {
  async init({ commit, dispatch, rootState: { auth } }) {
    const { data: response } = await axios
      .get(apiUrl + "/api/qlik/ticket", {
        headers: { Authorization: `Bearer ${auth.accessToken}` }
      })
      .catch(() => dispatch("auth/logout", undefined, { root: true }));
    const { ticket } = response.data;

    const requireJS = document.createElement("script");
    requireJS.src = `${protocol}${host}${prefix}resources/assets/external/requirejs/require.js?qlikTicket=${ticket}`;
    requireJS.onload = () => {
      window.requirejs.config({
        baseUrl: `${protocol}${host}${prefix}resources`
      });
      window.requirejs(["js/qlik"], qlik => {
        qlik.setOnError(() => {});
        commit("setQlik", qlik);

        qlik.getAppList(appList => {
          commit("setAppList", appList);
          if (appList.length === 1)
            return dispatch("openApp", appList[0].qDocId);

          if (router.currentRoute.path === "/") return;
          const previousAppId = localStorage.getItem("appId");
          if (appList.some(({ qDocId }) => qDocId === previousAppId))
            return dispatch("openApp", previousAppId);
        }, qlikConfig);
      });
    };
    document.head.appendChild(requireJS);
  },
  openApp: ({ state: { qlik, appList }, commit, dispatch }, appId) => {
    commit("setApp", {}); // clear the previously opened app (if any)
    const app = Object.assign(
      qlik.openApp(appId, qlikConfig),
      appList.find(({ qDocId }) => qDocId === appId)
    );
    const { VUE_APP_COUCHDB_NAME: dbName } = process.env;
    const fetchAppConfig = couchdb.doc.get(dbName, app.qDocName);

    const goToApp = (appConfig = {}) => {
      const { routes, ...rest } = getConsolidatedAppConfig(appConfig);

      commit("setApp", Object.assign(app, { ...rest, routes }));
      router.resetRoutes();
      router.addRoutes(routes);

      const { path } = router.currentRoute;
      const { matched } = router.resolve(path).resolved;
      if (path === "/" || !matched.length) router.push(routes[0].path);
    };

    return fetchAppConfig.then(goToApp).catch(err => {
      if (err.statusCode === 404) return goToApp();
      dispatch("auth/logout", undefined, { root: true });
    });
  }
};

export default { namespaced: true, state, getters, mutations, actions };
