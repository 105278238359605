import Vue from "vue";
import VueRouter from "vue-router";
import AppSelector from "@/components/AppSelector.vue";
import unauthorizedRoutes from "@/Unauthorized/routes.js";
import configurationRoutes from "@/Configuration/routes.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: AppSelector
  },
  ...unauthorizedRoutes,
  ...configurationRoutes
];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });

const router = createRouter();
router.resetRoutes = () => {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
};

export default router;
