<template>
  <li class="o-sidebar-navItem">
    <router-link
      v-if="!children"
      :to="{ name }"
      class="o-sidebar-navLink noselect"
      active-class="is-active"
      :style="meta.icon ? '' : 'padding-left: 0'"
    >
      <i>
        <fa v-if="meta.icon" :icon="['fal', meta.icon]" size="lg" fixed-width />
        <div v-else class="inline-block w-8"></div>
      </i>
      {{ meta.label }}
    </router-link>

    <div
      v-else
      class="o-sidebar-navLink noselect cursor-pointer"
      :class="{ 'is-active': isCurrentRoute }"
      @click="subNavExpended = !subNavExpended"
    >
      <i>
        <fa v-if="meta.icon" :icon="['fal', meta.icon]" size="lg" fixed-width />
        <div v-else class="inline-block w-8"></div>
      </i>
      {{ meta.label }}
    </div>

    <transition name="el-fade-in-linear">
      <ul v-if="children" v-show="subNavExpended" class="o-sidebar-subNav">
        <SideBarNavItem
          v-for="{ name, ...rest } in children"
          :key="name"
          v-bind="{ name, ...rest }"
        />
      </ul>
    </transition>
  </li>
</template>

<script>
export default {
  name: "SideBarNavItem",
  props: {
    name: { type: String, default: "" },
    children: Array,
    meta: { type: Object, required: true }
  },
  data: () => ({ subNavExpended: true }),
  computed: {
    isCurrentRoute() {
      return this.name === this.$route.name;
    }
  }
};
</script>

<style scoped>
a:hover {
  cursor: pointer;
}

.o-sidebar-navLink.is-active {
  font-weight: bold;
}

.o-sidebar-navLink.is-active:after {
  border-right: 1rem solid #f2f4f9;
}
</style>
