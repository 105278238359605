import auth0 from "auth0-js";
import operator from "@/config/operator.js";

const { auth0Config } = operator;
const webAuth = new auth0.WebAuth(auth0Config);

const state = () => ({ token: "", accessToken: "", expiresAt: null, user: {} });

const getters = {
  isAuthenticated: ({ expiresAt }) => new Date().getTime() < expiresAt
};

const mutations = {
  setAuth: Object.assign,
  removeAuth: currentState => Object.assign(currentState, state())
};

const actions = {
  login: () => webAuth.authorize(),
  logout: ({ commit, dispatch }) => {
    commit("removeAuth");
    commit("qlik/resetState", undefined, { root: true });
    webAuth.logout();
    dispatch("login");
  },
  handleAuthentication: ({ commit, dispatch }) =>
    webAuth.parseHash((_err, authResult) => {
      if (!authResult) return dispatch("logout");

      history.replaceState("", "", "/");
      const { idToken, accessToken, expiresIn, idTokenPayload } = authResult;

      commit("setAuth", {
        token: idToken,
        accessToken,
        expiresAt: expiresIn * 1000 + new Date().getTime(),
        user: idTokenPayload
      });

      dispatch("qlik/init", undefined, { root: true });
    })
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
