<template>
  <div class="spinner">
    <div
      class="bounce1"
      :style="{ 'background-color': theme.spinnerColor }"
    ></div>
    <div
      class="bounce2"
      :style="{ 'background-color': theme.spinnerColor }"
    ></div>
    <div
      class="bounce3"
      :style="{ 'background-color': theme.spinnerColor }"
    ></div>
  </div>
</template>

<script>
import operator from "@/config/operator.js";

export default {
  name: "Spinner",
  computed: { theme: () => operator.theme }
};
</script>

<style scoped>
.spinner > div {
  width: 15px;
  height: 15px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
