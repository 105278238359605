import Slouch from "couch-slouch";

const {
  VUE_APP_COUCHDB_URL: url,
  VUE_APP_COUCHDB_USER: user,
  VUE_APP_COUCHDB_PASSWD: passwd
} = process.env;
const slouch = new Slouch(url);

slouch.user.logIn(user, passwd);

export default slouch;
