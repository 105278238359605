<template>
  <Drawer :visible="open" @update:visible="toggleOpen" class="cursor-auto">
    <div class="flex flex-col p-8 h-screen">
      <div class="flex items-center">
        <div class="flex-grow text-left text-4xl poppins text-gray-900">
          Marque-pages
        </div>
        <el-tooltip
          v-show="mode === 'list'"
          content="Pour sauvegarder un marque-page, commencez par filtrer vos données"
          placement="bottom"
          :disabled="!!selections.length"
        >
          <span>
            <el-button
              type="primary"
              @click="setMode('create')"
              :disabled="!selections.length"
            >
              Sauvegarder
            </el-button>
          </span>
        </el-tooltip>
      </div>
      <hr class="mt-4 mb-8" />
      <Bookmarks
        v-show="mode === 'list' && !bookmarkToEdit"
        class="flex-grow"
      />
      <UpSertBookmark
        v-if="mode === 'create' || bookmarkToEdit"
        @close="closeUpSertBookmark"
        :bookmark-to-edit="bookmarkToEdit"
        class="flex-grow"
      />
    </div>
  </Drawer>
</template>

<script>
import Bookmarks from "./components/Bookmarks/Bookmarks.vue";
import UpSertBookmark from "./components/UpSertBookmark.vue";
import Drawer from "@/components/Drawer.vue";
import bookmarksDrawerStore from "./bookmarksDrawer.store.js";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapQlikState } = createNamespacedHelpers("qlik");
const {
  mapState: mapBookmarksDrawerState,
  mapMutations: mapBookmarksDrawerMutations
} = createNamespacedHelpers("bookmarksDrawer");

export default {
  components: { Bookmarks, UpSertBookmark, Drawer },
  data: () => ({
    bookmarkToEdit: null,
    bookmarkToDelete: null,
    bookmarks: [],
    selectionState: {},
    selections: [],
    backCount: 0,
    forwardCount: 0
  }),
  beforeCreate() {
    this.$store.registerModule("bookmarksDrawer", bookmarksDrawerStore);
  },
  created() {
    this.app.getList(
      "BookmarkList",
      ({ qBookmarkList: { qItems } }) => (this.bookmarks = qItems)
    );

    // We need to know the selection state to enable/disable the create btn
    const refreshSelectionState = ({ selections, backCount, forwardCount }) =>
      Object.assign(this.$data, { selections, backCount, forwardCount });

    this.selectionState = this.app.selectionState();
    this.selectionState.OnData.bind(() =>
      refreshSelectionState(this.selectionState)
    );
    refreshSelectionState(this.selectionState);
  },
  provide() {
    return { bookmarksContext: this.$data };
  },
  computed: {
    ...mapQlikState(["app"]),
    ...mapBookmarksDrawerState(["open", "mode"])
  },
  methods: {
    ...mapBookmarksDrawerMutations(["toggleOpen", "setMode"]),
    closeUpSertBookmark() {
      this.setMode("list");
      this.bookmarkToEdit = null;
    }
  },
  destroyed() {
    this.$store.unregisterModule("bookmarksDrawer");
  }
};
</script>

<style scoped></style>
